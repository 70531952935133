import _ from 'lodash';;
import moment from 'moment'

import React, { useEffect, useState, useContext, memo } from 'react';

import * as Linking from 'expo-linking';

import { database } from 'firebase';

import { UserContext } from '../context/UserContext';
import MultiLocationWebNavigation from '../components/MultiLocationWebNavigation';

import { View, Text, StyleSheet, Image, Dimensions, ScrollView, TouchableOpacity, PixelRatio } from 'react-native';
import { Textarea, Badge, Button, Application, Modal, Spinner } from 'react-rainbow-components';
import { firebaseMessagesDB } from '../services/firebase';
import Footer from '../components/Footer';
import WebsiteHeader from '../components/WebsiteHeader';

const deviceWidth = Dimensions.get("window").width;
const deviceHeight = Dimensions.get("window").height;

const emptyUser = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAIAAABvFaqvAAAABmJLR0QA/wD/AP+gvaeTAAACbElEQVQ4ja2VzW6rMBCFhyjGYInIIKcoClm0qKv2/Z+i26iLqs0itAI5EW6pjE0r+S7myiHpz72LzNJ4vjnHYw/B3d0dnCMmZ6EAwPTb1SAIOOecc8ZYGIYAMAyD1loppZRyzv0XKE3T5XJJKR0vRlEURVGWZdbaqqqUUr+BgiBYLpd5ngNA3/e73a7rOmstAFBKkyQRQsRxXJZl0zRVVf0IQopzbrvdSim9FuT2fS+lFEKsVissNmYdQGmaIuXh4aHrOkRcXV3FcQwAWuvNZmOMkVIaY66vr/M8f39/9x4nY1MAsN1ukQIASBmGYRgGxtjl5SWud12HWoqiCILgCMQ5p5SieO8IKev1er1eIwttAoCUsu97Sinn/BQEALvdDn4N33jn3H6/94mHM2KMAcDb25vPMcZorRljNzc3ABCGodYaO4iBmzHxoIgQAgAfHx/j+pvNRmsdhiFSnp6exl+HYfCJcNL+kytrjLm/v8dzMcb8ZPNIEWrB13ASxpivFL/Zm/irSGsdRVGSJJgznU7TNE2ShDHmXWutu65r2/bz8xMAZrMZJh6BlFJZls3n8/1+v1gsLi4uJpOjwUAppZSmaVoURdM0dV0LITDxFGStjeP49vaWEILdVUr1fY/iCSFxHHPOsyxbLBZCCEKItfYU5JyrqqosS0KI1vrx8RGb4sNai2kvLy9lWWLXq6ryR37Qr5RqmgYAsLK/++PAOYWvr67r8TA5aj++oDzPV6vVfD6XUo7HyGw2E0Lgbajr+vn5+ajG15nNOS+K4mSwjT3+e7B5j6+vr2cYtQDgnGvbtm3bb79+G2f7i/wBBgF9qEzvmnMAAAAASUVORK5CYII=";

const shadow = { 
    shadowColor: "#000",
    shadowOffset: {
        width: 0,
        height: 0,
    },
    shadowOpacity: 0.06,
    shadowRadius: 20,
    elevation: 14,
    }

function ViewFlatmateListingScreen({ navProp }) {
    const [userInfo, setUserInfo] = useContext(UserContext);
    const [listingId, setListingId] = useState<string>();
    const [userLastOnline, setUserLastOnline] = useState<any>();
    const [loading, setLoading] = useState<boolean>(true);
    const [messageLoading, setMessageLoading] = useState<boolean>(false);
    const [listing, setListing] = useState<any>([]);
    const [message, setMessage] = useState();
    const [messageSent, setMessageSent] = useState(false);
    const [mediaHeight, setMediaHeight] = useState(412);
    const [showImage, setShowImage] = useState(false);
    const [photo, setPhoto] = useState('');

    useEffect(() => {
        fetchListing();
    }, [!_.isEmpty(listing)]);

    useEffect(() => {
        if(listingId && !_.isNil(userInfo.uid)) {
            fetchMessagingValid();
        }
    });

    const fetchMessagingValid = async () => {
        var mainDB = database();

        await mainDB
            .ref(`users/${userInfo.uid}/messages`)
            .once('value', snapshot => {
                _.map(snapshot.val(), (messageKey) => {
                    if(messageKey?.listingId === listingId) {
                        setMessageSent(true);
                    }
                })
            });

        await mainDB
            .ref(`users/${listing?.user?.uid}/lastOnline`)
            .once('value', snapshot => setUserLastOnline(snapshot.val()))
    }

    const fetchListing = async() => {
        var mainDB = database();

        Linking
        .getInitialURL()
        .then(async (url: any) => {
            var listingId = Linking.parse(url).queryParams?.id;
            setListingId(listingId);

            if(listingId) {
                await mainDB
                    .ref(`listings/New Zealand/${listingId}`)
                    .once('value', async (snapshot) => {
                        setListing(snapshot.val());
                        setLoading(false);
                    });

                if(!_.isEmpty(listing)) {
                    const currentView = listing?.views | 0;

                    const UpdateViewsData = {
                        [`listings/New Zealand/${listingId}/views`] : currentView + 1
                    }

                    await mainDB.ref().update(UpdateViewsData);
                }
            }
        });
    }

    const sendMessage = async() => {
        setMessageLoading(true);
        const timestamp = moment().unix();

        const mainDB = database();
        const messagesDB = database(firebaseMessagesDB);

        const userID = userInfo.uid;
        const userPhoto = userInfo.photoUrl;
        const userName = userInfo.firstName;

        const listingUserID = listing.user.uid;
        const listingUserPhoto = listing.user.photoUrl;
        const listingUserName = listing.user.firstName;
        const listingUserEmail = listing.user.email;
        
        const newMessageKey = messagesDB.ref('messages').push().key;

        const data = {
            listingId: listing.id,
            messageId: newMessageKey,
            lastUpdated: timestamp,
            //location: listing.location,
            type: 'Flatmate listing',
            owner: {
                ownerId: listingUserID,
                ownerPhoto: listingUserPhoto,
                ownerFirstName: listingUserName,
            },
            enquirer: {
                enquirerId: userID,
                enquirerPhoto: userPhoto ? userPhoto : emptyUser,
                enquirerFirstName: userName,
            },
            messages: {
                [timestamp]: {
                    id: userID,
                    from: userName,
                    photo: userPhoto,
                    message: message,
                }
            }
        }

        const newMessageData = {
            [`messages/${newMessageKey}/`] : data,
        }

        await messagesDB.ref().update(newMessageData);

        const messageRefData = {
            messageId: newMessageKey,
            listingId: listing.id,
        }

        const messageRefListorData = {
            messageId: newMessageKey,
            listingId: listing.id,
            notifications: 1
        }

        const messageEnquirerData = {
            userId: userID,
            userName: userName,
            userPhoto: userPhoto,
        }

        const updateUserMessageRef = {
            [`users/${userID}/messages/${newMessageKey}`] : messageRefData,
            [`users/${listingUserID}/messages/${newMessageKey}`] : messageRefListorData,
            [`listings/New Zealand/${listing.id}/enquiries/${userID}`] : messageEnquirerData,
        }

        await mainDB.ref().update(updateUserMessageRef);
        sendEmail(listingUserEmail, userName);

        setMessageSent(true);
        setMessageLoading(false);
    }

    const sendEmail = (listingUserEmail: any, userName: any) => {
        const api_key = 'key-239f4e5567ffdf4d96da108150d69b56';
        const domain = 'flatting.co.nz';
        var mailgun = require('mailgun-js')({apiKey: api_key, domain: domain});

        const data = {
            from: 'info@pharoscompany.com',
            to: listingUserEmail,
            subject: `Message received from ${userName}`,
            text: `${userName} has sent you a message on flatting.co.nz about your listing. \n\nPlease login and navigate to messages to check your messages. \n\nThis is an auto generated message. \n\n\nTeam @ flatting.co.nz`,
        };
        mailgun.messages().send(data, function (error: any, body: any) {
            console.log(error, body);
        });
    }

    const showUserImage = (photo: any) => {
        setPhoto(photo);
        setShowImage(true);
    }

    return (
        <View style={{ flex: 1, backgroundColor: '#f2f2f2' }}>
            <ScrollView 
                style={{ height: 100 }}
                contentContainerStyle={{ width: '100%', height: '100%', padding: 0 }} 
                nestedScrollEnabled
                showsHorizontalScrollIndicator={false}>
                <WebsiteHeader navProp={navProp} />
                {loading && <View style={[shadow, { height: '95%', width: '100%', borderRadius: 10, marginTop: 20, maxWidth: 1200, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white' }]}>
                    <Application theme={{ rainbow: { palette: { brand: '#fed800' } } }} style={{ width: '100%' }}><Spinner variant="brand" size="large" /></Application>
                </View>}
                <Modal 
                    id="image-modal" 
                    isOpen={showImage} 
                    onRequestClose={() => setShowImage(false)} 
                    size='large'
                    title='Photo Viewer'>
                        <Image
                            source={{ uri : photo }}
                            style={{ minHeight: deviceWidth < 505 ? 400 : deviceHeight * 0.6, width: 'auto', borderRadius: 10 }}
                            resizeMode='cover'
                        />
                </Modal>
                {!_.isEmpty(listing?.photos) && <View style={{ alignSelf: 'center', maxWidth: deviceWidth }}>
                    <ScrollView style={{ marginBottom: 5 }} 
                                contentContainerStyle={{ paddingTop: 10, paddingBottom: 10 }} 
                                showsVerticalScrollIndicator={true}
                                onContentSizeChange={(width, height) => {
                                    if(width > deviceWidth) {
                                        setMediaHeight(430);
                                    }
                                }}
                                horizontal>
                        {_.map(listing?.photos, (photo : any, id : any) => {
                            return (
                                <TouchableOpacity activeOpacity={1} key={id} onPress={() => showUserImage(photo)}>
                                    <Image
                                        key={id}
                                        loadingIndicatorSource={require('../assets/icons/loading.gif')}
                                        source={{ uri : photo }}
                                        style={{ width: 300, height: 400, margin: 5, borderRadius: 10 }}
                                        resizeMode='cover'
                                    />
                                </TouchableOpacity>
                            )
                        })}
                    </ScrollView>
                </View>}
            <View style={{ width: '100%', marginTop: !_.isEmpty(listing?.photos) ? 0 : 15, flexDirection: 'row', flexWrap: 'wrap', maxWidth: 1200, alignSelf: 'center', justifyContent: 'space-between', padding: deviceWidth < 500 ? 15 : 30, paddingBottom: 15, borderRadius: 10, backgroundColor: 'white' }}>
            <View style={{ width: deviceWidth < 850 ? '100%' : '68%' }}>
                <Text style={{ fontSize: 28, fontFamily: 'pt-regular', paddingBottom: 10 }}>{listing?.name}</Text>
                <Text style={{ fontSize: 18, fontFamily: 'pt-regular', marginBottom: 25 }}>{listing?.age} years old | {listing?.gender}</Text>
                <Text style={{ marginBottom: 5, paddingVertical: 10, fontSize: 16 }}>Listing viewed <Text style={{ fontFamily: 'pt-bold' }}>{listing.views | 0} </Text>times</Text>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                    <View style={{ width: deviceWidth < 600 ? '100%' : 'fit-content', marginRight: 10, marginTop: 10, alignItems: 'center', paddingHorizontal: 40, paddingVertical: 20, borderRadius: 5, backgroundColor: '#fed800' }}>
                        <Text style={{ fontSize: 16 }}>Weekly budget</Text>
                        <Text style={{ fontSize: deviceWidth < 505 ? 25 : 30, paddingBottom: 5, fontFamily: 'pacifico-regular' }}>${listing?.rent}</Text>
                    </View>
                    <View style={{ width: deviceWidth < 600 ? '100%' : 'fit-content', marginRight: 10, marginTop: 10, alignItems: 'center', paddingHorizontal: 40, paddingVertical: 20, borderRadius: 5, backgroundColor: '#fed800' }}>
                        <Text style={{ fontSize: 16 }}>Move date</Text>
                        <Text style={{ fontSize: deviceWidth < 505 ? 25 : 30, paddingBottom: 5, fontFamily: 'pacifico-regular' }}>{moment.unix(listing?.date).format('LL')}</Text>
                    </View>
                    <View style={{ width: deviceWidth < 600 ? '100%' : 'fit-content', marginRight: 10, marginTop: 10, alignItems: 'center', paddingHorizontal: 40, paddingVertical: 20, borderRadius: 5, backgroundColor: '#fed800' }}>
                        <Text style={{ fontSize: 16 }}>Looking for</Text>
                        <Text style={{ fontSize: deviceWidth < 505 ? 25 : 30, paddingBottom: 5, fontFamily: 'pacifico-regular' }}>{listing?.placeFor}</Text>
                    </View>
                </View>
                <View style={{ paddingVertical: 30, borderBottomWidth: 1, borderColor: '#eee' }}>
                    <Text style={{ fontSize: 22, paddingBottom: 20 }}>About Me</Text>
                    <Text style={{ fontSize: 18, fontFamily: 'pt-regular', lineHeight: 32 }}>{listing?.details}</Text>
                </View>
                <View  style={{ paddingVertical: 30, borderBottomWidth: 0, borderColor: '#eee' }}>
                    <Text style={{ fontSize: 22, paddingBottom: 20 }}>Employment Details</Text>
                    <Text style={{ fontSize: 18, fontFamily: 'pt-regular', lineHeight: 32 }}>{listing?.employment}</Text>
                </View>
                <View style={{ padding: 20, borderColor: '#576574', borderWidth: 2, borderRadius: 10, flexDirection: 'row', marginTop: 20 }}>
                    <Image source={require('../assets/icons/animated-chat.gif')} style={{ height: 25, width: 25 }} />
                    <View style={{ paddingHorizontal: 15, width: '100%' }}>
                        <Text style={{ fontSize: 22, fontFamily: 'pt-regular', paddingBottom: 10, display: "flex", alignItems: 'center' }}>Message {listing?.user?.firstName} with your questions about this listing</Text>
                        <Text style={{ fontSize: 16, fontFamily: 'pt-regular', maxWidth: 500, lineHeight: 25, color: '#2a3039' }}>You can also message to request a viewing. Make sure to introduce yourself and ask about what times are available.</Text>
                    </View>
                </View>
                <View style={{ paddingVertical: 30, borderBottomWidth: 1, borderTopWidth: 1, borderColor: '#eee', marginTop: 30 }}>
                    <Text style={{ fontSize: 22, paddingBottom: 20 }}>Property Preferences</Text>
                    <Text style={{ fontSize: 16, fontFamily: 'pt-Regular', paddingVertical: 10, display: "flex", alignItems: 'center' }}><Image source={{ uri: "https://img.icons8.com/ios/25/000000/person-at-home.png"}} style={{ height: 25, width: 25 }} />  Property type <Text style={{ fontFamily: 'pt-bold' }}> {listing?.propertyType}</Text></Text>
                    <Text style={{ fontSize: 16, fontFamily: 'pt-Regular', paddingVertical: 10, display: "flex", alignItems: 'center' }}><Image source={{ uri: "https://img.icons8.com/ios/25/000000/conference-call--v1.png"}} style={{ height: 25, width: 25 }} />  Max no. of flatmates <Text style={{ fontFamily: 'pt-bold' }}> {listing?.maxFlatmates}</Text></Text>
                    <Text style={{ fontSize: 16, fontFamily: 'pt-Regular', paddingVertical: 10, display: "flex", alignItems: 'center' }}><Image source={{ uri: "https://img.icons8.com/ios/25/000000/shower.png"}} style={{ height: 25, width: 25 }} />  Bathroom <Text style={{ fontFamily: 'pt-bold' }}> {listing?.bathroom}</Text></Text>
                    <Text style={{ fontSize: 16, fontFamily: 'pt-Regular', paddingVertical: 10, display: "flex", alignItems: 'center' }}><Image source={{ uri: "https://img.icons8.com/ios/25/000000/sleeper-chair.png"}} style={{ height: 25, width: 25 }} />  Furnishings <Text style={{ fontFamily: 'pt-bold' }}> {listing?.furnishings}</Text></Text>
                    <Text style={{ fontSize: 16, fontFamily: 'pt-Regular', paddingVertical: 10, display: "flex", alignItems: 'center' }}><Image source={{ uri: "https://img.icons8.com/ios/25/000000/parking.png"}} style={{ height: 25, width: 25 }} />  Parking <Text style={{ fontFamily: 'pt-bold' }}> {listing?.parking}</Text></Text>
                    <Text style={{ fontSize: 16, fontFamily: 'pt-Regular', paddingVertical: 10, display: "flex", alignItems: 'center' }}><Image source={{ uri: "https://img.icons8.com/ios/25/000000/wifi--v1.png"}} style={{ height: 25, width: 25 }} />  Internet <Text style={{ fontFamily: 'pt-bold' }}> {listing?.internet}</Text></Text>
                </View>
                <View style={{ paddingVertical: 30 }}>
                    <Text style={{ fontSize: 22, paddingBottom: 20 }}>Preferred Location</Text>
                    <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                    {_.map(listing?.location, (location, id) => <Text key={id} style={{ fontSize: deviceWidth < 500 ? 14 : 16, alignItems: 'center', paddingHorizontal: 20, paddingVertical: 10, borderRadius: 30, borderWidth: 1, borderColor: 'gray', marginBottom: 10, marginRight: 10 }}>{location.label} </Text>)}
                    </View>
                </View>
                {listing?.location && <MultiLocationWebNavigation addresses={listing?.location} />}
                </View>
                <View style={{ width: deviceWidth < 850 ? '100%' : '30%' }}>
                    <Application theme={{ rainbow: { palette: { brand: '#000' } } }} style={{ width: '100%' }}>
                        <View style={{ width: '100%', padding: 20, borderRadius: 10, backgroundColor: '#f2f2f2', alignItems: 'center', justifyContent: 'center' }}>
                            <Image source={{ uri: listing?.user?.photoUrl }} style={{ height: 100, width: 100, borderRadius: 50 }} />
                            <Text style={{ fontSize: 22, paddingTop: 10 }}>{listing?.user?.firstName}</Text>
                                <Badge
                                    style={{ marginTop: 5, marginBottom: 15, fontFamily: 'pt-regular', backgroundColor: '#576574' }}
                                    label={`Last online ${moment.unix(userLastOnline).fromNow()}`}
                                    variant='inverse'
                                />
                            {(!messageSent) && <View style={{ width: '100%' }}>
                                <Textarea
                                    id="message"
                                    rows={6}
                                    disabled={userInfo?.uid === listing?.user?.uid}
                                    onChange={(e: any) => setMessage(e.target.value)}
                                    placeholder="Message user about an available room"
                                    style={{ maxWidth: 700, width: '100%' }}
                                />
                                <Button
                                    isLoading={messageLoading}
                                    label="Send Message"
                                    disabled={!message || !userInfo.uid}
                                    onClick={() => sendMessage()}
                                    variant="outline-brand"
                                    style={{ width: '100%', marginTop: 10, backgroundColor: 'white' }}
                                />
                            </View>}
                            {(messageSent && userInfo.uid) && <View style={{ width: '100%', borderColor: '#576574', borderWidth: 1, borderRadius: 5, overflow: 'hidden' }}>
                                <Text style={{ width: '100%', alignSelf: 'center', padding: 15, textAlign: 'center', fontFamily: 'pt-regular', fontSize: 18, color: 'white', backgroundColor: '#576574' }}>
                                    Message Sent
                                </Text>
                                <Text style={{ padding: 10, paddingHorizontal: 20, borderRadius: 5, backgroundColor: 'white', marginTop: 0, textAlign: 'center' }}>You can go to your messages screen to view your conversation with {listing?.user?.firstName}</Text>
                            </View>}
                            {!userInfo.uid && <View style={{ width: '100%' }}>
                                <Text style={{ width: '100%', padding: 10, textAlign: 'center', fontFamily: 'pt-bold', color: '#f9494b', borderColor: '#f9494b', borderWidth: 1, borderRadius: 30, marginTop: 10 }}>Please login to message owner</Text>
                            </View>}
                        </View>
                    </Application>
                </View>
            </View>
            <View style={{ width: '100%', height: 20 }}></View>
            <Footer />
        </ScrollView>
    </View>
    );
}

export default memo(ViewFlatmateListingScreen);