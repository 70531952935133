import _ from 'lodash';
import moment from 'moment';
import { database } from 'firebase';
import React, { useState, useEffect, useContext } from 'react';

import { Platform, View, Text, Image, TouchableOpacity, StyleSheet, Dimensions } from 'react-native';
import { ButtonGroup } from 'react-native-elements';

import { ScrollView } from 'react-native-gesture-handler';

import { UserContext } from '../context/UserContext';
import { Application, DatePicker, Input, Button, ProgressIndicator, ProgressStep, Textarea, Spinner } from 'react-rainbow-components';

import * as Linking from 'expo-linking';

import GooglePlacesInputWeb from '../components/GooglePlacesInputWeb';
import ConfirmFlatmatesScreen from '../components/ConfirmFlatmatesScreen';
import WebsiteHeader from '../components/WebsiteHeader';
import EditImageUploaderWeb from '../components/EditImageUploaderWeb';

const deviceWidth = Dimensions.get("window").width;
const deviceHeight = Dimensions.get("screen").height;

const PropertyType = ['Flexible', 'Apartment', 'House', 'Lifestyle Block', 'Townhouse', 'Unit'];
const Furnishings = ['Flexible', 'Not required', 'Required'];
const BathroomType = ['Flexible', 'Ensuite or own required'];
const Internet = ['Flexible', 'Required'];
const Parking = ['Flexible', 'Off-street Required'];
const MaxFlatemates = ['Flexible', '1 other', '2 other', '3 other'];

const PlaceFor = ['Me', 'Couple'];
const Gender = ['Female', 'Male'];

const stepNames = ['details', 'photos', 'confirm'];

const deleteIcon = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAABmJLR0QA/wD/AP+gvaeTAAAEWElEQVR4nO2cQWsbRxiGn1kdejHY7cWq6b30VhqoSqOkoWmwkUlv/iXB5Opbcmn/SI5KFCeh4GI5jWkJ9FYK7ak1hkCxIYeESDM9jNcUNfJKmtmdb8L3HOXRfKP3We1aO7MDiqIoiqIoiqIoiqIoiqIoipIXDkzqMdRJHZ8vSod/9b78tFUUd4GvgAJ47Mx4e63/028x+k/N0ebVT2B8xxTmGo73gB8LuL16f/hraN/BAs7C3weWJjo+wbLRHgwPQ2uk5LjX7VCw62Bl4k8vC+iGSihC3gxwduQvTb7uYMW1eHTc63ZCa6TiuNftuBaP3hI+wJI13A2tESTA7VDgTztTGrCcq4QyfBzLUxs5roVeF4IEmB0s4C5slKGEmcL3WFP1+SsIPgU55x5XN8pHwhzhg2E3tF74NcC6WwZOKhtmIGGe8A2cFJbt0JrBAlYfPv3DGnMd+KeycSnh5uUvQuvG5mize8kVPJzxyD/FsrH6YPhnaN1oPyyObl75zDj3BPhghqqnBrfR7h88i1U/hKPN7iVjeAK8X9nYcGrGrMf69zrqL7scJaQM33cZmZwkpA7fd1sDOUiQEL7vuiYkS5ASvu++RiRKkBS+L1EzkiRIC9+XaQAJEiSG70s1REoJUsP35RokhQTJ4fuSDdOkBOnh+7IJaEJCDuH70omoU0Iu4fvyCalDQk7h+yEkJqaE3ML3wxBADAk5hu+HIoQQCbmGD4IEwGISrDVvcg0fhAmAuSfFTwFmbSstfBAoAOb8JsyC0PBBqACIKEFw+CBYAESQIDx8EC4AAiRkED5kIAAWkJBJ+BBhYVYjWGeY92BpuSwOLvGDnOtH1n8Rsu6oCtECFg6/JAMJYgUEh18iXIJIAdHCLxEsQdxF+LjX7ZiCH5jx3s757YiLcCw7zK7EpfGiBMy7RNyMWXeYr8l4abyYU1DILWUJ644WRYSAGPfzc5WQXEDMyZQcJaSdlK9hJis3CemWpdQ4jZiThDQLsxqYw81FQvNLExucQM9BQrOLcxOsXpAuobnl6QmXjkiW0MwDGgLW7UiVUP8jSgLCPx+LQAn1PqQnKPzzMQmTUN9jqgLDL5EkoZ4HtQWHXyJFQvytCjIIv0SChLibdWQUfklqCfG2q8kw/JKUEqIIyDn8klQSggX8/e3lj1vWPJuyteNkNZHhl8y7ZdnI0vloMPw9pGbwnHBhi+/fhfAB2oPhoRmzPstEv4OVVsF3oTXD9g3d2mqB+6ayYQbhl7QHw8OZJ/rhhs9gccK+AS9emMo+Mgq/ZK2//9wZc4MqCSbCGSTkzWZvbwQcTG+QX/gla/3958bSu/B05Dgw9+6NQ+qErwsydht49b+X4STX8EvKa8KUfVFfOevS7xv6Yf/pzxh7FdgDRsBroF9QfJ5z+CXtwfBwZOkA9/GfbQTsOeuurA0Ofkk7ugnc1lbrbEPvdxK3QxF60VUURVEURVEURVEURVEURVGUBPwLrYqXL280myUAAAAASUVORK5CYII=";

export default function EditFlatmateListingScreen({ navProp }) {
  const [userInfo, setUserInfo] = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [listing, setListing] = useState<any>([]);

  const [detailsValid, setDetailsValid] = useState(false);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const [rent, setRent] = useState(0);
  const [date, setDate] = useState(new Date());

  const [propertyTypeIndex, setPropertyTypeIndex] = useState();
  const [propertyType, setPropertyType] = useState('');

  const [furnishingsIndex, setFurnishingsIndex] = useState();
  const [furnishings, setFurnishings] = useState('');

  const [internetIndex, setInternetIndex] = useState();
  const [internet, setInternet] = useState('');

  const [bathroomIndex, setBathroomIndex] = useState();
  const [bathroom, setBathroom] = useState('');

  const [parkingIndex, setParkingIndex] = useState();
  const [parking, setParking] = useState('');

  const [maxFlatmatesIndex, setMaxFlatmatesIndex] = useState();
  const [maxFlatmates, setMaxFlatmates] = useState('');

  const [location, setLocation] = useState<any>([]);

  const [placeForIndex, setPlaceForIndex] = useState();
  const [placeFor, setPlaceFor] = useState('');

  const [genderIndex, setGenderIndex] = useState();
  const [gender, setGender] = useState('');

  const [name, setName] = useState('');
  const [age, setAge] = useState('0');

  const [employment, setEmployment] = useState<string>('');
  const [details, setDetails] = useState<string>('');

  const [userPhotos, setUserPhotos] = useState();

  const shadow = { 
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.06,
    shadowRadius: 20,
    elevation: 14,
  }

  useEffect(() => {
    if (rent && date && propertyType && !_.isEmpty(location) && name && age && gender && placeFor && maxFlatmates && parking && bathroom && internet && furnishings) {
      setDetailsValid(true);
    } else {
      setDetailsValid(false);
    }
  });

  useEffect(() => {
    if(userInfo.uid != '') {
      setName(userInfo.firstName);
      setLoading(false);
    }

    setTimeout(() => {
      setLoading(false);
    }, 7000);
  }, [userInfo.uid != ''])

  useEffect(() => {
    fetchListing();
  }, [!_.isEmpty(listing)]);

  const fetchListing = async() => {
      var mainDB = database();

      Linking
      .getInitialURL()
      .then((url: any) => {
          var listingId = Linking.parse(url).queryParams?.id;

          if(listingId) {
              mainDB
                  .ref(`listings/New Zealand/${listingId}`)
                  .once('value', snapshot => {
                      let listing = snapshot.val();
                      let date = moment.unix(listing.date).toDate();
                      let propertyIndex: any = _.indexOf(PropertyType, listing?.propertyType);
                      let furnishingsIndex: any = _.indexOf(Furnishings, listing?.furnishings);
                      let internetIndex: any = _.indexOf(Internet, listing?.internet);
                      let bathroomIndex: any = _.indexOf(BathroomType, listing?.bathroom);
                      let parkingIndex: any = _.indexOf(Parking, listing?.parking);
                      let maxFlatmatesIndex: any = _.indexOf(MaxFlatemates, listing?.maxFlatmates);

                      let placeForIndex: any = _.indexOf(PlaceFor, listing?.placeFor);
                      let genderIndex: any = _.indexOf(Gender, listing?.gender);

                      setPropertyTypeIndex(propertyIndex);
                      setFurnishingsIndex(furnishingsIndex);
                      setInternetIndex(internetIndex);
                      setBathroomIndex(bathroomIndex);
                      setParkingIndex(parkingIndex);
                      setMaxFlatmatesIndex(maxFlatmatesIndex);

                      setPlaceForIndex(placeForIndex);
                      setGenderIndex(genderIndex);

                      setListing(listing);
                      setRent(listing.rent);
                      setAge(listing.age);
                      setName(listing.name);
                      setPropertyType(listing.propertyType);
                      setPlaceFor(listing.placeFor);
                      setGender(listing.gender);
                      setDate(date);
                      setMaxFlatmates(listing.maxFlatmates);
                      setBathroom(listing.bathroom);
                      setLocation(listing.location);
                      setParking(listing.parking);
                      setInternet(listing.internet);
                      setFurnishings(listing.furnishings);

                      setDetails(listing.details);
                      setEmployment(listing.employment);

                      setUserPhotos(listing.photos);
                      setLoading(false);
                  })
          }
      });
  }

  const updateListing = async () => {
    let userID = userInfo.uid;
    let country = 'New Zealand';
    const searchLocations = _.map(location, location => {
      const suburb = location?.value?.terms[location.value.terms.length - 3]?.value;
      const city = location?.value?.terms[location.value.terms.length - 2]?.value;
      return { suburb, city }
    })
    
    const listingData = {
        id: listing.id,
        cities: searchLocations,
        created: listing.created,
        updated: moment().unix(),
        user: userInfo,
        rent: rent,
        date: moment(date).unix(),
        propertyType: propertyType,
        bathroom: bathroom,
        maxFlatmates: maxFlatmates,
        location: location,
        parking: parking,
        internet: internet,
        furnishings: furnishings,
        details: details,
        employment: employment,
        placeFor: placeFor,
        age: age,
        name: name,
        views: listing?.views,
        gender: gender,
        photos: userPhotos,
        status: 'open',
        type: 'person',
    }

    const dataToUpdate = {
        [`listings/${country}/${listing.id}`] : listingData,
    }

    await database().ref().update(dataToUpdate);
    await database().ref(`users/${userID}/listingInProgress`).remove();

    navProp.navigation.navigate('profile');
  }

  const updatePropertyType = (value: any) => {
    setPropertyTypeIndex(value);
    setPropertyType(PropertyType[value]);
  }

  const updateFurnishings = (value: any) => {
    setFurnishingsIndex(value);
    setFurnishings(Furnishings[value]);
  }

  const updateInternet = (value: any) => {
    setInternetIndex(value);
    setInternet(Internet[value]);
  }

  const updateBathroom = (value: any) => {
    setBathroomIndex(value);
    setBathroom(BathroomType[value]);
  }

  const updateParking = (value: any) => {
    setParkingIndex(value);
    setParking(Parking[value]);
  }

  const updateMaxFlatemates = (value: any) => {
    setMaxFlatmatesIndex(value);
    setMaxFlatmates(MaxFlatemates[value]);
  }

  const updatePlaceFor = (value: any) => {
    setPlaceForIndex(value);
    setPlaceFor(PlaceFor[value]);
  }

  const updateGender = (value: any) => {
    setGenderIndex(value);
    setGender(Gender[value]);
  }

  const updateUserPhotos = (photos: any) => { setUserPhotos(photos) }

  return (
    <View style={{ flex: 1, backgroundColor: '#fafafa' }}>
    <ScrollView 
      style={styles.container}
      contentContainerStyle={{ width: '100%', height: '100%' }}
      showsHorizontalScrollIndicator={false}>
      <WebsiteHeader navProp={navProp} />
      {loading && <View style={[shadow, { height: '95%', width: '100%', borderRadius: 10, marginTop: 20, maxWidth: 1200, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white' }]}>
        <Application theme={{ rainbow: { palette: { brand: '#fed800' } } }} style={{ width: '100%' }}><Spinner variant="brand" size="large" /></Application>
      </View>}
      {(!userInfo.uid && !loading) && <View style={[shadow, { height: '95%', width: '100%', borderRadius: 10, marginTop: 20, maxWidth: 1200, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white' }]}>
        <Image source={require('../assets/images/login.svg')} style={{ width: deviceWidth < 600 ? '85%' : '40%', height: deviceWidth < 600 ? 200 : 300, resizeMode: 'contain' }} />
        <Text style={{ paddingTop: 40, paddingBottom: 20, fontSize: 25, fontFamily: 'pacifico-regular', textAlign: 'center' }}>Login Required</Text>
        <Text style={{ paddingTop: 10, fontSize: 16, color: 'gray', textAlign: 'center' }}>Looks like you're not logged In.</Text>
        <Text style={{ paddingTop: 5, fontSize: 16, color: 'gray', textAlign: 'center' }}>Please login or signup to create a flatmate listing.</Text>
      </View>}
      {userInfo.firstName ? <Application theme={{ rainbow: { palette: { brand: '#3f4954' } } }}>
        <View style={{ width: '100%', height: 65, alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
            <ProgressIndicator currentStepName={stepNames[currentStepIndex]}>
                <ProgressStep name="details" label='Details' />
                <ProgressStep name="photos" label='Photos' />
                <ProgressStep name="confirm" label='Confirm' />
            </ProgressIndicator>
        </View>
      </Application> : null}
      {(currentStepIndex === 0 && userInfo.uid) ? <View style={{ width: '100%', maxWidth: 1200, alignSelf: "center", justifyContent: 'space-between', alignItems: 'center', marginTop: 30 }}>
        <Image source={{ uri: userInfo.photoUrl }} style={{ height: 60, width: 60, borderRadius: 30, marginBottom: 10 }} />
        <Text style={{ fontSize: 22, paddingVertical: 10, paddingHorizontal: 25, lineHeight: 32, fontFamily: 'pacifico-regular', textAlign: 'center' }}>Hi {userInfo.firstName}, lets get started</Text>
        <Text style={{fontSize: 16, paddingBottom: 10, paddingTop: 10, color: 'gray' }}><Text style={{ color: 'red' }}>* </Text>Indicates a required field.</Text>
        <View style={[shadow, { width: '100%', padding: 30, marginTop: 20, borderRadius: 10, backgroundColor: 'white', marginBottom: deviceWidth < 500 ? 10 : 20 }]}>
          <Text style={{ fontSize: 22, fontWeight: 'bold', paddingBottom: 20, color: '#464646' }}>Price And Availability</Text>
          <Text style={{ fontSize: 16, paddingBottom: 10 }}>Weekly budget?<Text style={{ color: 'red' }}> *</Text></Text>
          <Application theme={{ rainbow: { palette: { brand: '#fed800' } } }}>
            <Input
              style={{ marginBottom: 30, maxWidth: 150 }}
              placeholder='0.00'
              value={rent}
              maxLength={6}
              onChange={(e : any) => setRent(e.target.value)}
              type='number'
              icon={<Image source={{ uri: "https://img.icons8.com/material-rounded/96/000000/us-dollar.png" }} style={{ height: 15, width: 15, opacity: 0.6 }} />}
             />
          </Application>
          <Text style={{ fontSize: 16, paddingBottom: 10 }}>How long would you like your listing to last for?<Text style={{ color: 'red' }}> *</Text></Text>
          <Application theme={{ rainbow: { palette: { brand: '#fed800' } } }}>
          <DatePicker
                id="datePicker-1"
                value={date}
                style={{ fontFamily: 'arial', width: '100%', maxWidth: 150, marginBottom: 5 }}
                onChange={setDate}
                minDate={new Date()}
                formatStyle="medium"
                locale='en'
            />
            </Application>
        </View>
        <View style={[shadow,{ width: '100%', padding: 30, marginTop: 20, borderRadius: 10, backgroundColor: 'white', marginBottom: deviceWidth < 500 ? 10 : 20 }]}>
            <Text style={{ fontSize: 22, fontWeight: 'bold', paddingBottom: 20, color: '#464646' }}>Your Ideal Property</Text>
            <Text style={{ fontSize: 16, paddingBottom: 10 }}>Property Type<Text style={{ color: 'red' }}> *</Text></Text>
            <View style={{ flexDirection: deviceWidth < 1000 ? 'column' : 'row', paddingBottom: 20 }}>
              <ButtonGroup
                onPress={updatePropertyType}
                selectedIndex={propertyTypeIndex}
                buttons={PropertyType}
                vertical={deviceWidth < 500 ? true : false}
                containerStyle={{ minHeight: 60, width: '100%', marginLeft: 0, marginRight: 0, borderRadius: 50 }}
                buttonContainerStyle= {{ marginLeft: 0, marginRight: 0 }}
                selectedButtonStyle={{ backgroundColor: '#fed800' }}
                textStyle={{ textAlign: 'center', fontSize: 16 }}
              />
            </View>
            <Text style={{ fontSize: 16, paddingBottom: 10 }}>Room furnishings<Text style={{ color: 'red' }}> *</Text></Text>
            <View style={{ flexDirection: deviceWidth < 1000 ? 'column' : 'row', paddingBottom: 20 }}>
              <ButtonGroup
                onPress={updateFurnishings}
                selectedIndex={furnishingsIndex}
                buttons={Furnishings}
                vertical={deviceWidth < 500 ? true : false}
                containerStyle={{ minHeight: 60, width: '100%', marginLeft: 0, marginRight: 0, borderRadius: 50 }}
                buttonContainerStyle= {{ marginLeft: 0, marginRight: 0 }}
                selectedButtonStyle={{ backgroundColor: '#fed800' }}
                textStyle={{ textAlign: 'center', fontSize: 16 }}
              />
            </View>
            <Text style={{ fontSize: 16, paddingBottom: 10 }}>Internet<Text style={{ color: 'red' }}> *</Text></Text>
            <View style={{ flexDirection: deviceWidth < 1000 ? 'column' : 'row', paddingBottom: 20 }}>
              <ButtonGroup
                onPress={updateInternet}
                selectedIndex={internetIndex}
                buttons={Internet}
                vertical={deviceWidth < 500 ? true : false}
                containerStyle={{ minHeight: 60, width: '100%', marginLeft: 0, marginRight: 0, borderRadius: 50 }}
                buttonContainerStyle= {{ marginLeft: 0, marginRight: 0 }}
                selectedButtonStyle={{ backgroundColor: '#fed800' }}
                textStyle={{ textAlign: 'center', fontSize: 16 }}
              />
            </View>
            <Text style={{ fontSize: 16, paddingBottom: 10 }}>Bathroom Type<Text style={{ color: 'red' }}> *</Text></Text>
            <View style={{ flexDirection: deviceWidth < 1000 ? 'column' : 'row', paddingBottom: 20 }}>
              <ButtonGroup
                onPress={updateBathroom}
                selectedIndex={bathroomIndex}
                buttons={BathroomType}
                vertical={deviceWidth < 500 ? true : false}
                containerStyle={{ minHeight: 60, width: '100%', marginLeft: 0, marginRight: 0, borderRadius: 50 }}
                buttonContainerStyle= {{ marginLeft: 0, marginRight: 0 }}
                selectedButtonStyle={{ backgroundColor: '#fed800' }}
                textStyle={{ textAlign: 'center', fontSize: 16 }}
              />
            </View>
            <Text style={{ fontSize: 16, paddingBottom: 10 }}>Parking<Text style={{ color: 'red' }}> *</Text></Text>
            <View style={{ flexDirection: deviceWidth < 1000 ? 'column' : 'row', paddingBottom: 20 }}>
              <ButtonGroup
                onPress={updateParking}
                selectedIndex={parkingIndex}
                buttons={Parking}
                vertical={deviceWidth < 500 ? true : false}
                containerStyle={{ minHeight: 60, width: '100%', marginLeft: 0, marginRight: 0, borderRadius: 50 }}
                buttonContainerStyle= {{ marginLeft: 0, marginRight: 0 }}
                selectedButtonStyle={{ backgroundColor: '#fed800' }}
                textStyle={{ textAlign: 'center', fontSize: 16 }}
              />
            </View>
            <Text style={{ fontSize: 16, paddingBottom: 10 }}>Maximum number of flatmates<Text style={{ color: 'red' }}> *</Text></Text>
            <View style={{ flexDirection: deviceWidth < 1000 ? 'column' : 'row', paddingBottom: 20 }}>
              <ButtonGroup
                onPress={updateMaxFlatemates}
                selectedIndex={maxFlatmatesIndex}
                buttons={MaxFlatemates}
                vertical={deviceWidth < 500 ? true : false}
                containerStyle={{ minHeight: 60, width: '100%', marginLeft: 0, marginRight: 0, borderRadius: 50 }}
                buttonContainerStyle= {{ marginLeft: 0, marginRight: 0 }}
                selectedButtonStyle={{ backgroundColor: '#fed800' }}
                textStyle={{ textAlign: 'center', fontSize: 16 }}
              />
            </View>
        </View>
        <View style={[shadow,{ width: '100%', padding: 30, marginTop: 20, borderRadius: 10, backgroundColor: 'white', marginBottom: deviceWidth < 500 ? 10 : 20 }]}>
          <Text style={{ fontSize: 22, fontWeight: 'bold', paddingBottom: 20, color: '#464646' }}>Location<Text style={{ color: 'red' }}> *</Text></Text>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            {_.map(location, (location, id) => <View key={id} style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 20, paddingVertical: 10, borderRadius: 30, borderWidth: 1, borderColor: 'gray', marginBottom: 10, marginRight: 10 }}>
              <Text style={{ fontSize: deviceWidth < 500 ? 14 : 16 }}>{location.label}  </Text>
              <TouchableOpacity onPress={() => setLocation(location => {
                location.splice(id, 1);
                return [...location]
              })}><Image source={{ uri: deleteIcon }} style={{ width: 20, height: 20 }} /></TouchableOpacity>
            </View>)}
          </View>
          <GooglePlacesInputWeb updateLocation={(incomingLocation) => setLocation((location) => { return [...location, incomingLocation] })} />
          <Text style={{ fontSize: 16, fontWeight: 'normal', paddingBottom: 5, paddingTop: 20, color: '#464646' }}>Enter name of suburb or city</Text>
          <Text style={{ fontSize: 12, fontWeight: 'normal', paddingBottom: 10, color: '#464646' }}>Make sure not to enter exact address or your listing might not appear in searches</Text>
        </View>
        <View style={[shadow,{ width: '100%', padding: 30, marginTop: 20, borderRadius: 10, backgroundColor: 'white', marginBottom: deviceWidth < 500 ? 10 : 20, position: 'relative', zIndex: -1 }]}>
          <Text style={{ fontSize: 22, fontWeight: 'bold', paddingBottom: 20, color: '#464646' }}>About you</Text>
          <Text style={{ fontSize: 16, paddingBottom: 10 }}>This place is for<Text style={{ color: 'red' }}> *</Text></Text>
            <View style={{ flexDirection: deviceWidth < 650 ? 'column' : 'row', paddingBottom: 20 }}>
              <ButtonGroup
                onPress={updatePlaceFor}
                selectedIndex={placeForIndex}
                buttons={PlaceFor}
                vertical={deviceWidth < 500 ? true : false}
                containerStyle={{ minHeight: 40, width: deviceWidth < 650 ? '100%' : '60%', marginLeft: 0, marginRight: 0, borderRadius: 50, borderWidth: 0.5, borderColor: '#a4a7b5', }}
                buttonContainerStyle= {{ marginLeft: 0, marginRight: 0 }}
                selectedButtonStyle={{ backgroundColor: '#fed800' }}
                textStyle={{ textAlign: 'center', fontSize: 16 }}
              />
            </View>
            <Text style={{ fontSize: 16, paddingBottom: 10 }}>Your first name<Text style={{ color: 'red' }}> *</Text></Text>
            <View style={{ flexDirection: deviceWidth < 650 ? 'column' : 'row', paddingBottom: 20 }}>
                <Application theme={{ rainbow: { palette: { brand: '#fed800' } } }} style={{ width: deviceWidth < 650 ? '100%' : '60%' }}>
                    <Input 
                        onChange={event => setName(event.target.value)}
                        value={name}
                        placeholder='Enter fist name...'
                        maxLength={50}
                        style={{ marginBottom: 5, fontSize: 18 }}
                    />
                </Application>
            </View>
            <Text style={{ fontSize: 16, paddingBottom: 10 }}>Age<Text style={{ color: 'red' }}> *</Text></Text>
            <View style={{ flexDirection: deviceWidth < 1000 ? 'column' : 'row', paddingBottom: 20 }}>
                <Application theme={{ rainbow: { palette: { brand: '#fed800' } } }} style={{ width: 150 }}>
                    <Input 
                        onChange={event => setAge(event?.target?.value)}
                        value={age}
                        type='number'
                        placeholder='Enter age...'
                        maxLength={2}
                        style={{ marginBottom: 5, fontSize: 18 }}
                    />
                </Application>
            </View>
            <Text style={{ fontSize: 16, paddingBottom: 10 }}>Gender<Text style={{ color: 'red' }}> *</Text></Text>
            <View style={{ flexDirection: deviceWidth < 650 ? 'column' : 'row', paddingBottom: 20 }}>
              <ButtonGroup
                onPress={updateGender}
                selectedIndex={genderIndex}
                buttons={Gender}
                vertical={deviceWidth < 500 ? true : false}
                containerStyle={{ minHeight: 40, width: deviceWidth < 650 ? '100%' : '60%', marginLeft: 0, marginRight: 0, borderRadius: 50, borderWidth: 1, borderColor: '#a4a7b5', }}
                buttonContainerStyle= {{ marginLeft: 0, marginRight: 0 }}
                selectedButtonStyle={{ backgroundColor: '#fed800' }}
                textStyle={{ textAlign: 'center', fontSize: 16 }}
              />
            </View>
        </View>
        <View style={[shadow,{ width: '100%', padding: 30, marginTop: 20, borderRadius: 10, backgroundColor: 'white', marginBottom: deviceWidth < 500 ? 10 : 20 }]}>
          <Text style={{ fontSize: 22, fontWeight: 'bold', paddingBottom: 20, color: '#464646' }}>Employment Status</Text>
          <Application theme={{ rainbow: { palette: { brand: '#fed800' } } }}>
            <Textarea 
              onChange={event => setEmployment(event.target.value)}
              value={employment}
              placeholder='e.g. Working fulltime, working part-time, working holiday, retired, unemployed, backpacker, student...'
              maxLength={3000}
              rows={3}
              style={{ borderRadius: 10, borderWidth: 0.5, borderColor: '#e3e3e3', width: deviceWidth < 650 ? '100%' : '60%', marginBottom: 5, fontSize: 18, lineHeight: 25 }}
            />
          </Application>
        </View>
        <View style={[shadow,{ width: '100%', padding: 30, marginTop: 20, borderRadius: 10, backgroundColor: 'white', marginBottom: deviceWidth < 500 ? 10 : 20 }]}>
          <Text style={{ fontSize: 22, fontWeight: 'bold', paddingBottom: 20, color: '#464646' }}>What makes you great to live with?</Text>
          <Application theme={{ rainbow: { palette: { brand: '#fed800' } } }}>
            <Textarea 
              onChange={event => setDetails(event.target.value)}
              value={details}
              placeholder="Tell your potential flatmates a little about yourself. What do you like to do for fun and where you are from. Also remember to let them know what you're looking for."
              maxLength={3000}            
              rows={5}
              style={{ borderRadius: 10, borderWidth: 0.5, borderColor: '#e3e3e3', width: deviceWidth < 650 ? '100%' : '60%', marginBottom: 5, fontSize: 18, lineHeight: 25 }}
            />
          </Application>
        </View>
        <View style={{ width: '100%', borderRadius: 10, marginBottom: 30, backgroundColor: 'white', padding: 20 }}>
          <Application theme={{ rainbow: { palette: { brand: '#fed800' } } }} style={{ width: deviceWidth < 550 ? '100%' : '30%', alignSelf: 'flex-end' }}>
            <Button
                label="Next"
                onClick={() => setCurrentStepIndex(1)}
                variant="brand"
                style={{ borderRadius: 5, color: !detailsValid ? 'gray' : 'black', width: '100%', }}
                disabled={!detailsValid}
            />
          </Application>
        </View>
      </View> : null}
      {(currentStepIndex === 1 && userInfo.uid) ?
      <View style={{ flex: 1, width: '100%', maxWidth: 1200, alignSelf: 'center' }}>
        <Text style={{ fontSize: 22, paddingBottom: 5, paddingTop: 30, paddingLeft: 15, fontWeight: 'bold' }}>Photos</Text>
        <Text style={{ fontSize: 16, color: 'gray', paddingBottom: 20, paddingLeft: 15, }}>Upload up to 15 photos for your listing, making sure that they are good quality and small in size. Images that take too long to load up can put some users off.</Text>
        <EditImageUploaderWeb userInfo={userInfo} updateUserPhotos={updateUserPhotos} photos={userPhotos} listingId={listing.id} />
        <View style={{ width: '100%', borderRadius: 10, marginTop: 20, marginBottom: 30, backgroundColor: 'white', padding: 20, flexDirection: 'row', justifyContent: 'space-between' }}>
        <Application theme={{ rainbow: { palette: { brand: '#000' } } }} style={{ width: deviceWidth < 550 ? '100%' : '30%', alignSelf: 'flex-start', marginRight: deviceWidth < 505 ? 5 : 0 }}>
              <Button
                  label="Previous"
                  onClick={() => setCurrentStepIndex(0)}
                  variant="outline-brand"
                  style={{ borderRadius: 5, width: '100%' }}
              />
          </Application>
          <Application theme={{ rainbow: { palette: { brand: '#fed800' } } }} style={{ width: deviceWidth < 550 ? '100%' : '30%', alignSelf: 'flex-end' }}>
              <Button
                  label="Next"
                  onClick={() => setCurrentStepIndex(2)}
                  variant="brand"
                  style={{ borderRadius: 5, color: '#000', width: '100%', }}
              />
          </Application>
        </View>
      </View> : null}
      {(currentStepIndex === 2 && userInfo.uid) ?
      <View style={{ width: '100%', maxWidth: 1200, alignSelf: 'center' }}>
        <Text style={{ fontSize: 22, paddingBottom: 5, paddingTop: 30, paddingLeft: 15, fontWeight: 'bold' }}>Confirm</Text>
        <Text style={{ fontSize: 16, color: 'gray', paddingBottom: 20, paddingLeft: 15, }}>Please make sure that you're happy with your listing details</Text>
        <ConfirmFlatmatesScreen 
            userInfo={userInfo}
            rent={rent}
            date={date} 
            propertyType={propertyType}
            furnishings={furnishings}
            internet={internet}
            bathroomType={bathroom}
            parking={parking}
            maxflatmates={maxFlatmates}
            location={location}
            placefor={placeFor}
            name={name}
            age={age}
            gender={gender}
            employment={employment}
            details={details}
            userPhotos={userPhotos}
          />
        <View style={{ width: '100%', borderRadius: 10, marginTop: 0, marginBottom: 30, backgroundColor: 'white', padding: 20, flexDirection: 'row', justifyContent: 'space-between' }}>
        <Application theme={{ rainbow: { palette: { brand: '#000' } } }} style={{ width: deviceWidth < 550 ? '100%' : '30%', alignSelf: 'flex-start', marginRight: deviceWidth < 505 ? 5 : 0 }}>
              <Button
                  label="Previous"
                  onClick={() => setCurrentStepIndex(1)}
                  variant="outline-brand"
                  style={{ borderRadius: 5, width: '100%', }}
              />
          </Application>
          <Application theme={{ rainbow: { palette: { brand: '#fed800' } } }} style={{ width: deviceWidth < 550 ? '100%' : '30%', alignSelf: 'flex-end' }}>
              <Button
                  onClick={updateListing}
                  label="Update"
                  variant="brand"
                  style={{ borderRadius: 5, color: '#000', width: '100%', }}
              />
            </Application>
          </View>
      </View> : null}
    </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: Platform.OS === 'web' ? 100 : deviceHeight,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  information: { 
    width: deviceWidth < 1000 ? '90%' : '48.5%', 
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  picker: {
    height: 50,
    width: '100%',
    marginRight: 15,
    marginLeft: 15,
    borderRadius: 50,
    borderColor: '#bdc6cf',
    paddingLeft: 0,
    paddingRight: 0,
    fontSize: 16,
    fontWeight: '500',
    borderWidth: 0,
    color: '#5e6977',
  }, 
  pickerItem: {
    padding: 10,
  },
});
