import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { Platform, View, Text, Image, TouchableOpacity, TextInput, StyleSheet, Dimensions, ActivityIndicator } from 'react-native';

const deviceWidth = Dimensions.get("window").width;
const deviceHeight = Dimensions.get("screen").height;

const ConfirmScreen = ({ userInfo, rent, bond, bills, date, propertyType, bedroomsValue, bathroomsValue, flatmates, location, parking, internet, details, pets, couples, smokers, currentFlatmates, idealFlatmates, amenities, furnishings, userPhotos }) => {
    return (
        <View style={{ flex: 1, width: '100%', height: '100%', borderRadius: 10, marginTop: 20, marginBottom: 20, backgroundColor: 'white', padding: 30 }}>
            <Text style={{ fontSize: 22, fontWeight: 'bold', paddingBottom: 20, color: '#464646' }}>Listing Details</Text>
                <Text style={{ fontSize: 16, paddingBottom: 10 }}>Rent per week</Text>
                <Text style={{ fontSize: 20, paddingBottom: 25 }}>${rent}</Text>
                <Text style={{ fontSize: 16, paddingBottom: 10 }}>Bond</Text>
                <Text style={{ fontSize: 20, paddingBottom: 25 }}>{bond}</Text>
                <Text style={{ fontSize: 16, paddingBottom: 10 }}>Bills</Text>
                <Text style={{ fontSize: 20, paddingBottom: 25 }}>{bills}</Text>
                <Text style={{ fontSize: 16, paddingBottom: 10 }}>Room available from</Text>
                <Text style={{ fontSize: 20, paddingBottom: 25 }}>{moment(date).format('LL')}</Text>
                <View style={{ width: '100%', height: 1, backgroundColor: '#eee', marginTop: 15, marginBottom: 20 }} />
                <View style={{ flexDirection: deviceWidth < 500 ?  'column' : 'row', width: '100%', justifyContent: deviceWidth < 500 ? 'flex-start' : 'space-between', maxWidth: 800 }}>
                    <View>
                        <Text style={{ fontSize: 16, paddingBottom: 10 }}>Property type</Text>
                        <Text style={{ fontSize: 20, paddingBottom: 25 }}>{propertyType}</Text>
                    </View>
                    <View>
                        <Text style={{ fontSize: 16, paddingBottom: 10 }}>Bedrooms</Text>
                        <Text style={{ fontSize: 20, paddingBottom: 25 }}>{bedroomsValue}</Text>
                    </View>
                    <View>
                        <Text style={{ fontSize: 16, paddingBottom: 10 }}>Bathrooms</Text>
                        <Text style={{ fontSize: 20, paddingBottom: 25 }}>{bathroomsValue}</Text>
                    </View>
                    <View>
                        <Text style={{ fontSize: 16, paddingBottom: 10 }}>Existing Flatmates</Text>
                        <Text style={{ fontSize: 20, paddingBottom: 25 }}>{flatmates}</Text>
                    </View>
                </View>
                <View style={{ width: '100%', height: 1, backgroundColor: '#eee', marginTop: 15, marginBottom: 20 }} />
                <Text style={{ fontSize: 16, paddingBottom: 10 }}>Location</Text>
                <Text style={{ fontSize: 20, paddingBottom: 25 }}>{location?.label}</Text>
                <View style={{ width: '100%', height: 1, backgroundColor: '#eee', marginTop: 15, marginBottom: 20 }} />
                <Text style={{ fontSize: 16, paddingBottom: 10 }}>Parking</Text>
                <Text style={{ fontSize: 20, paddingBottom: 25 }}>{parking.label}</Text>
                <Text style={{ fontSize: 16, paddingBottom: 10 }}>Internet</Text>
                <Text style={{ fontSize: 20, paddingBottom: 25 }}>{internet.label}</Text>
                <View style={{ width: '100%', height: 1, backgroundColor: '#eee', marginTop: 15, marginBottom: 20 }} />
                <Text style={{ fontSize: 16, paddingBottom: 10 }}>Details about the flat</Text>
                <Text style={{ fontSize: 20, paddingBottom: 25 }}>{details}</Text>
                <View style={{ width: '100%', height: 1, backgroundColor: '#eee', marginTop: 15, marginBottom: 20 }} />
                <View style={{ flexDirection: deviceWidth < 500 ?  'column' : 'row', width: '100%', justifyContent: deviceWidth < 500 ? 'flex-start' : 'space-between', maxWidth: 700 }}>
                    <View style={{ }}>
                        <Text style={{ fontSize: 16, paddingBottom: 10 }}>Pets allowed?</Text>
                        <Text style={{ fontSize: 20, paddingBottom: 25 }}>{pets}</Text>
                    </View>
                    <View style={{ }}>
                        <Text style={{ fontSize: 16, paddingBottom: 10 }}>Open to couples?</Text>
                        <Text style={{ fontSize: 20, paddingBottom: 25 }}>{couples}</Text>
                    </View>
                    <View style={{ }}>
                        <Text style={{ fontSize: 16, paddingBottom: 10 }}>Smoking allowed?</Text>
                        <Text style={{ fontSize: 20, paddingBottom: 25 }}>{smokers}</Text>
                    </View>
                </View>
                <View style={{ width: '100%', height: 1, backgroundColor: '#eee', marginTop: 15, marginBottom: 20 }} />
                <Text style={{ fontSize: 16, paddingBottom: 10 }}>Current Flatmates</Text>
                <Text style={{ fontSize: 20, paddingBottom: 25 }}>{currentFlatmates}</Text>
                <View style={{ width: '100%', height: 1, backgroundColor: '#eee', marginTop: 15, marginBottom: 20 }} />
                <Text style={{ fontSize: 16, paddingBottom: 10 }}>Ideal Flatmates</Text>
                <Text style={{ fontSize: 20, paddingBottom: 25 }}>{idealFlatmates}</Text>
                <View style={{ width: '100%', height: 1, backgroundColor: '#eee', marginTop: 15, marginBottom: 20 }} />
                <Text style={{ fontSize: 16, paddingBottom: 10 }}>Amenities</Text>
                <Text style={{ fontSize: 20, paddingBottom: 25 }}>{amenities}</Text>
                <View style={{ width: '100%', height: 1, backgroundColor: '#eee', marginTop: 15, marginBottom: 20 }} />
                <Text style={{ fontSize: 16, paddingBottom: 10 }}>Furnishings</Text>
                <Text style={{ fontSize: 20, paddingBottom: 25 }}>{furnishings}</Text>
                <Text style={{ fontSize: 22, fontWeight: 'bold', paddingBottom: 20, paddingTop: 25, color: '#464646' }}>Photos</Text>
                <View style={{ flexDirection: 'row', width: '100%', flexWrap: 'wrap', marginLeft: -10 }}>
                    {_.map(userPhotos, (images: any, id: any) => (
                        <View key={id}>
                            <Image source={{ uri: images }} style={{ width: deviceWidth < 500 ? 120 : 200, height: deviceWidth < 500 ? 120 : 200, margin: deviceWidth < 500 ? 5 : 10, borderRadius: 10 }} />
                        </View>
                    ))}
                </View>
        </View>
    );
}

const styles = StyleSheet.create({

});

export default ConfirmScreen;