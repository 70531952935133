import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { Platform, View, Text, Image, TouchableOpacity, TextInput, StyleSheet, Dimensions, ActivityIndicator } from 'react-native';

const deviceWidth = Dimensions.get("window").width;
const deviceHeight = Dimensions.get("screen").height;

const ConfirmScreen = ({ userInfo, rent, date, propertyType, furnishings, internet, bathroomType, parking, maxflatmates, location, placefor, name, age, gender, employment, details, userPhotos }) => {
    return (
        <View style={{ flex: 1, width: '100%', height: '100%', borderRadius: 10, marginTop: 20, marginBottom: 20, backgroundColor: 'white', padding: 30 }}>
                <Text style={{ fontSize: 26, fontFamily: 'pt-regular', paddingBottom: 10 }}>{name}</Text>
                <Text style={{ fontSize: 16, fontFamily: 'pt-regular', marginBottom: 25 }}>{age} years old | {gender}</Text>

                <View style={{ paddingVertical: 30, flexDirection: 'row', flexWrap: 'wrap', borderTopWidth: 1, borderBottomWidth: 1, borderColor: '#eee' }}>
                    <View style={{ margin: 10, marginLeft: 0, alignItems: 'center', paddingHorizontal: 40, paddingVertical: 20, borderRadius: 5, borderWidth: 1, borderColor: 'gray' }}>
                        <Text style={{ fontSize: 22, paddingBottom: 15 }}>${rent}</Text>
                        <Text style={{ fontSize: 16 }}>Weekly budget</Text>
                    </View>
                    <View style={{ margin: 10, alignItems: 'center', paddingHorizontal: 40, paddingVertical: 20, borderRadius: 5, borderWidth: 1, borderColor: 'gray' }}>
                        <Text style={{ fontSize: 22, paddingBottom: 15 }}>{moment(date).format('LL')}</Text>
                        <Text style={{ fontSize: 16 }}>Move date</Text>
                    </View>
                    <View style={{ margin: 10, alignItems: 'center', paddingHorizontal: 40, paddingVertical: 20, borderRadius: 5, borderWidth: 1, borderColor: 'gray' }}>
                        <Text style={{ fontSize: 22, paddingBottom: 15 }}>{placefor}</Text>
                        <Text style={{ fontSize: 16 }}>Looking for</Text>
                    </View>
                </View>
                <View style={{ paddingVertical: 30, borderBottomWidth: 1, borderColor: '#eee' }}>
                    <Text style={{ fontSize: 22, paddingBottom: 15 }}>About Me</Text>
                    <Text style={{ fontSize: 16, fontFamily: 'pt-regular' }}>{details}</Text>
                </View>
                <View  style={{ paddingVertical: 30, borderBottomWidth: 1, borderColor: '#eee' }}>
                    <Text style={{ fontSize: 22, paddingBottom: 15 }}>Employment Details</Text>
                    <Text style={{ fontSize: 16, fontFamily: 'pt-regular' }}>{employment}</Text>
                </View>
                <View style={{ paddingVertical: 30, borderBottomWidth: 1, borderColor: '#eee' }}>
                    <Text style={{ fontSize: 22, paddingBottom: 15 }}>Property Preferences</Text>
                    <Text style={{ fontSize: 18, fontFamily: 'pt-Regular', paddingVertical: 10 }}>Property type <Text style={{ fontFamily: 'pt-bold' }}> {propertyType}</Text></Text>
                    <Text style={{ fontSize: 18, fontFamily: 'pt-Regular', paddingVertical: 10 }}>Max no. of flatmates <Text style={{ fontFamily: 'pt-bold' }}> {maxflatmates}</Text></Text>
                    <Text style={{ fontSize: 18, fontFamily: 'pt-Regular', paddingVertical: 10 }}>Bathroom <Text style={{ fontFamily: 'pt-bold' }}> {bathroomType}</Text></Text>
                    <Text style={{ fontSize: 18, fontFamily: 'pt-Regular', paddingVertical: 10 }}>Furnishings <Text style={{ fontFamily: 'pt-bold' }}> {furnishings}</Text></Text>
                    <Text style={{ fontSize: 18, fontFamily: 'pt-Regular', paddingVertical: 10 }}>Parking <Text style={{ fontFamily: 'pt-bold' }}> {parking}</Text></Text>
                    <Text style={{ fontSize: 18, fontFamily: 'pt-Regular', paddingVertical: 10 }}>Internet <Text style={{ fontFamily: 'pt-bold' }}> {internet}</Text></Text>
                </View>
                <View style={{ paddingVertical: 30, borderBottomWidth: 1, borderColor: '#eee' }}>
                    <Text style={{ fontSize: 22, paddingBottom: 15 }}>Preferred Location</Text>
                    <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                    {_.map(location, (location, id) => <Text key={id} style={{ fontSize: deviceWidth < 500 ? 14 : 16, alignItems: 'center', paddingHorizontal: 20, paddingVertical: 10, borderRadius: 30, borderWidth: 1, borderColor: 'gray', marginBottom: 10, marginRight: 10 }}>{location.label} </Text>)}
                    </View>
                </View>
                <View style={{ paddingTop: 30 }}>
                <Text style={{ fontSize: 22, paddingBottom: 15 }}>Your Photos</Text>
                <View style={{ flexDirection: 'row', width: '100%', flexWrap: 'wrap', marginLeft: -10 }}>
                    {_.map(userPhotos, (images: any, id: any) => (
                        <View key={id}>
                            <Image source={{ uri: images }} style={{ width: deviceWidth < 500 ? 120 : 200, height: deviceWidth < 500 ? 120 : 200, margin: deviceWidth < 500 ? 5 : 10, borderRadius: 10 }} />
                        </View>
                    ))}
                </View>
                </View>
        </View>
    );
}

export default ConfirmScreen;