import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { storage, database, auth } from 'firebase';

import { View, Image, Button, Text, TouchableOpacity, Dimensions } from 'react-native';

import * as ImagePicker from 'expo-image-picker';

const deviceWidth = Dimensions.get("window").width;
const deviceHeight = Dimensions.get("screen").height;

const ImageUploaderWeb = ({ userInfo, updateUserPhotos }) => {
    const [images, setImages] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [uploadProfileProgress, setUploadProfileProgress] = useState(0);
    const [uploadedImages, setUploadedImages] = useState({});

    useEffect(() => {
        if(_.isEmpty(uploadedImages)) {
            _fetchMyImages();
        }
    }, [uploadedImages]);

    const _fetchMyImages = async () => {
        let userID = userInfo.uid;
    
        const fetchPhotoURLRef = database().ref(`users/${userID}/listingInProgress/images`);
        await fetchPhotoURLRef.once("value", snapshot => { 
            setUploadedImages(snapshot.val());
            updateUserPhotos(snapshot.val());
        });
    };

    const _pickImage = async () => {
        if(_.size(uploadedImages) < 15) {
            let selectedImages = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                allowsEditing: true,
                allowsMultipleSelection: true,
                aspect: [4, 3],
                quality: 0.8,
            });
        
            if (!selectedImages.cancelled) {
            setImages(selectedImages.selected);
                _uploadNewImages(selectedImages.selected)
                .then(() => _fetchMyImages());
            }
        } else {
            setErrorMessage('Please delete some images in order to upload more images.');
        }
    };
    
    const _uploadNewImages = async (selectedImages: any) => {
        const userID = userInfo.uid;

        selectedImages.forEach(async (element: any) => {
            const response = await fetch(element.uri);
            const blob = await response.blob();
            const imageName = Math.floor(Math.random() * Math.floor(50000));
        
            var uploadTask = storage()
            .ref("userPhotos/")
            .child(`${userID}/listingPhotos/listingInProgress/${imageName}`)
            .put(blob);
        
            uploadTask.on(
                "state_changed",
                snapshot => {
                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 1000;
            
                    setUploadProfileProgress(progress);
                    if (progress === 1000) {
                    setTimeout(() => {
                        setUploadProfileProgress(0);
                    }, 4000);
                    }
                },
                function (error) {
                    alert(error.toString());
                },
                function () {
                    uploadTask.snapshot.ref.getDownloadURL().then(async downloadURL => {
                    setUploadedImages((prevState : any) => ({ ...prevState, [imageName]: downloadURL }));
            
                    await database()
                        .ref(`users/${userID}/listingInProgress/images`)
                        .update({ [imageName]: downloadURL });
                    });

                    _fetchMyImages();
                }
            );
        });
    };

    const _removeImage = async (id: any) => {
        const userID = userInfo.uid;

        await storage()
            .ref("userPhotos/")
            .child(`${userID}/listingPhotos/listingInProgress/${id}`)
            .delete();
        
        await database()
            .ref(`users/${userID}/listingInProgress/images/${id}`)
            .remove();
        
        _fetchMyImages();
    };

    return (
        <View style={{ width: '100%', backgroundColor: 'white', padding: deviceWidth < 500 ? 10 : 20, borderRadius: 10 }}>
            <TouchableOpacity 
                onPress={_pickImage}
                style={{ width: '100%', height: 200, borderWidth: 2, backgroundColor: '#f7f7f7', borderColor: '#b7b7b7', borderStyle: 'dashed', borderRadius: 10, justifyContent: 'center', alignItems: 'center' }}>
                <Image source={{ uri: "https://img.icons8.com/plasticine/100/000000/image.png" }} style={{ height: 100, width: 100 }} />
                <Text style={{ fontSize: 18, fontWeight: 'bold', color: 'gray' }}>Click to upload Image</Text>
            </TouchableOpacity>
            <Text style={{ padding: deviceWidth < 500 ? 0 : 10, color: 'red' }}>{errorMessage}</Text>
            <View style={{ flexDirection: 'row', width: '100%', flexWrap: 'wrap', justifyContent: deviceWidth < 500 ? 'center' : 'flex-start' , paddingTop: deviceWidth < 500 ? 20 : 40 }}>
                {_.map(uploadedImages, (images: any, id: any) => (
                    <View key={id}>
                        <TouchableOpacity 
                            onPress={() => _removeImage(id)}
                            style={{ position: 'absolute', right: 15, top: 15, zIndex: 1, width: 35, height: 35, alignItems: 'center', justifyContent: 'center', borderRadius: 30, backgroundColor: 'white' }}>
                            <Image source={require('../assets/icons/trash.gif')} style={{ width: 20, height: 20, }} />
                        </TouchableOpacity>
                        <Image source={{ uri: images }} style={{ width: deviceWidth < 500 ? 125 : 150, height: deviceWidth < 500 ? 125 : 150, margin: deviceWidth < 500 ? 5 : 10, borderRadius: 10 }} />
                    </View>
                ))}
            </View>
        </View>
    );
}

export default ImageUploaderWeb;