import * as React from 'react';
import {
    Text,
    View,
    TouchableOpacity,
} from 'react-native';
import { Modal, Input, Button, Application } from 'react-rainbow-components';
import { FirebaseRecaptchaVerifierModal, FirebaseRecaptchaBanner } from 'expo-firebase-recaptcha';

import * as firebase from 'firebase';

export default function PhoneVerification({showWebSignIn, closeWebSignIn} : { showWebSignIn: boolean, closeWebSignIn: any }) {
    const recaptchaVerifier = React.useRef<any>();
    const [phoneNumber, setPhoneNumber] = React.useState<any>('');
    const [hidePhoneNumber, setHidePhoneNumber] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [verificationId, setVerificationId] = React.useState<any>();
    const [verificationCode, setVerificationCode] = React.useState<any>('');
    const [message, showMessage] = React.useState<any>(undefined);

    const firebaseConfig = firebase.apps.length ? firebase.app().options : undefined;
    const attemptInvisibleVerification = true;

    const enterPhoneNumber = ({ nativeEvent }) => {
        let incomingData = nativeEvent.target.value;
        setPhoneNumber(incomingData.replace(/\s/g, ''));
    }

    const sendVerificationCode = async () => {
        try {
            setLoading(true);
            const phone = phoneNumber;

            const phoneProvider = new firebase.auth.PhoneAuthProvider();
            const verificationId = await phoneProvider.verifyPhoneNumber(phone.replace('0', "+64"), recaptchaVerifier?.current);

            setVerificationId(verificationId);
            showMessage({ text: 'Verification code has been sent to your phone.' });
            setHidePhoneNumber(true);
            setLoading(false);
        } catch (err) {
            showMessage({ text: `Error: ${err.message}`, color: 'red' });
            setLoading(false);
        }
    }

    const confirmAndLinkAccount = async () => {
        try {
            setLoading(true);
            const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
            var prevUser = firebase.auth().currentUser;

            await prevUser?.linkWithCredential(credential)
                    .then(async (result) => {
                        let db = firebase.database();
                        let updateUserDetailsData = {
                            [`users/${result.user?.uid}/phoneVerified`] : true,
                            [`users/${result.user?.uid}/phoneNumber`] : phoneNumber
                        }
                        
                        await db.ref().update(updateUserDetailsData);
                        showMessage({ text: 'Phone authentication successful 👍' });
                        setLoading(false);
                        closeWebSignIn();
                        window.location.reload();
                    });
        } catch (err) {
            showMessage({ text: `Error: ${err.message}`, color: 'red' });
            setLoading(false);
        }
    }

    return (
    <Application style={{ width: '100%' }} theme={{ rainbow: { palette: { brand: '#fed800' } } }}>
        <Modal 
            size='small'
            isOpen={showWebSignIn} 
            title='Verify Phone Number'
            onRequestClose={closeWebSignIn}>
            <View style={{ padding: 20, paddingTop: 0, paddingBottom: 60 }}>
            <FirebaseRecaptchaVerifierModal
                ref={recaptchaVerifier}
                firebaseConfig={firebaseConfig}
                attemptInvisibleVerification={attemptInvisibleVerification}
            />
            {!hidePhoneNumber && <View>
                <Text style={{ fontFamily: 'pt-regular', fontSize: 14, paddingBottom: 15 }}>
                    Verifying your phone number is a great way to show other users that your account is real. We highly recommend verifying your phone number. 
                </Text>
                <Text style={{ fontSize: 16, fontFamily: 'pt-bold', paddingBottom: 10 }}>Phone number</Text>
                <Input
                    hideLabel
                    label="Phone Number"
                    type="tel"
                    isCentered={true}
                    style={{ marginBottom: 15, fontFamily: 'monospace', }}
                    placeholder="Enter your phone number"
                    autoComplete='tel'
                    onChange={enterPhoneNumber}
                    value={phoneNumber}
                />
                <Button
                    isLoading={loading}
                    label="Send Verification Code"
                    disabled={!phoneNumber || loading}
                    variant='brand'
                    style={{ borderRadius: 10, height: 50, marginBottom: 10 }}
                    onClick={sendVerificationCode}
                />
            </View>}
            {hidePhoneNumber && <View>
                <Text style={{ fontSize: 16, fontFamily: 'pt-bold', paddingBottom: 10 }}>Verification Code</Text>
                <Input
                    hideLabel
                    label="Verification Code"
                    disabled={!verificationId || loading}
                    isCentered={true}
                    maxLength={6}
                    style={{ marginBottom: 15, fontFamily: 'monospace' }}
                    placeholder="Enter your 6 digit code"
                    onChange={e => setVerificationCode(e.target.value)}
                    value={verificationCode}
                />
                <Button
                    isLoading={loading}
                    label="Confirm Verification Code"
                    disabled={!verificationCode || loading}
                    style={{ borderRadius: 10, height: 50, marginBottom: 10 }}
                    variant='brand'
                    onClick={confirmAndLinkAccount}
                />
            </View>}
            {message ? (
                <TouchableOpacity
                style={[
                    { backgroundColor: '#fff', justifyContent: 'center' },
                ]}
                onPress={() => showMessage(undefined)}>
                <Text
                    style={{
                    color: message.color || 'green',
                    fontSize: 17,
                    textAlign: 'center',
                    margin: 20,
                    }}>
                    {message.text}
                </Text>
                </TouchableOpacity>
            ) : (
                undefined
            )}
            {attemptInvisibleVerification && <FirebaseRecaptchaBanner />}
            </View>
        </Modal>
    </Application>
    );
}