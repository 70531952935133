export const NZCities = [
    {
      "label": "Auckland",
      "lat": "-36.8500",
      "lng": "174.7833",
      "population": "1467800"
    },
    {
      "label": "Wellington",
      "lat": "-41.2889",
      "lng": "174.7772",
      "population": "418500"
    },
    {
      "label": "Christchurch",
      "lat": "-43.5309",
      "lng": "172.6365",
      "population": "377200"
    },
    {
      "label": "Manukau",
      "lat": "-36.9833",
      "lng": "174.8833",
      "population": "375600"
    },
    {
      "label": "Waitakere",
      "lat": "-36.8490",
      "lng": "174.5430",
      "population": "208100"
    },
    {
      "label": "Northcote",
      "lat": "-36.7913",
      "lng": "174.7758",
      "population": "205605"
    },
    {
      "label": "Hamilton",
      "lat": "-37.7833",
      "lng": "175.2833",
      "population": "169300"
    },
    {
      "label": "Tauranga",
      "lat": "-37.6858",
      "lng": "176.1667",
      "population": "135000"
    },
    {
      "label": "Dunedin",
      "lat": "-45.8667",
      "lng": "170.5000",
      "population": "126255"
    },
    {
      "label": "Lower Hutt",
      "lat": "-41.2167",
      "lng": "174.9167",
      "population": "103400"
    },
    {
      "label": "Palmerston North",
      "lat": "-40.3549",
      "lng": "175.6095",
      "population": "80300"
    },
    {
      "label": "Napier",
      "lat": "-39.4833",
      "lng": "176.9167",
      "population": "62800"
    },
    {
      "label": "Porirua",
      "lat": "-41.1333",
      "lng": "174.8500",
      "population": "55500"
    },
    {
      "label": "New Plymouth",
      "lat": "-39.0578",
      "lng": "174.0742",
      "population": "55300"
    },
    {
      "label": "Rotorua",
      "lat": "-38.1378",
      "lng": "176.2514",
      "population": "54500"
    },
    {
      "label": "Whangarei",
      "lat": "-35.7250",
      "lng": "174.3236",
      "population": "52600"
    },
    {
      "label": "Nelson",
      "lat": "-41.2931",
      "lng": "173.2381",
      "population": "49300"
    },
    {
      "label": "Invercargill",
      "lat": "-46.4290",
      "lng": "168.3620",
      "population": "48700"
    },
    {
      "label": "Upper Hutt",
      "lat": "-41.1333",
      "lng": "175.0500",
      "population": "41000"
    },
    {
      "label": "Whanganui",
      "lat": "-39.9333",
      "lng": "175.0500",
      "population": "39400"
    },
    {
      "label": "Gisborne",
      "lat": "-38.6625",
      "lng": "178.0178",
      "population": "35500"
    },
    {
      "label": "Paraparaumu",
      "lat": "-40.9167",
      "lng": "175.0167",
      "population": "28900"
    },
    {
      "label": "Timaru",
      "lat": "-44.4000",
      "lng": "171.2500",
      "population": "28300"
    },
    {
      "label": "Blenheim",
      "lat": "-41.5167",
      "lng": "173.9500",
      "population": "26400"
    },
    {
      "label": "Pukekohe East",
      "lat": "-37.1950",
      "lng": "174.9481",
      "population": "26300"
    },
    {
      "label": "Taupo",
      "lat": "-38.6900",
      "lng": "176.0800",
      "population": "23900"
    },
    {
      "label": "Masterton",
      "lat": "-40.9667",
      "lng": "175.6500",
      "population": "20100"
    },
    {
      "label": "Cambridge",
      "lat": "-37.8833",
      "lng": "175.4667",
      "population": "19150"
    },
    {
      "label": "Levin",
      "lat": "-40.6250",
      "lng": "175.2833",
      "population": "17700"
    },
    {
      "label": "Ashton",
      "lat": "-44.0330",
      "lng": "171.7720",
      "population": "17700"
    },
    {
      "label": "Macetown",
      "lat": "-44.8650",
      "lng": "168.8190",
      "population": "16600"
    },
    {
      "label": "Feilding",
      "lat": "-40.2250",
      "lng": "175.5650",
      "population": "16450"
    },
    {
      "label": "Rolleston",
      "lat": "-43.5960",
      "lng": "172.3830",
      "population": "16350"
    },
    {
      "label": "Whakatane",
      "lat": "-37.9640",
      "lng": "176.9840",
      "population": "15850"
    },
    {
      "label": "Richmond",
      "lat": "-41.3333",
      "lng": "173.1833",
      "population": "15000"
    },
    {
      "label": "Havelock North",
      "lat": "-39.6667",
      "lng": "176.8833",
      "population": "13950"
    },
    {
      "label": "Tokoroa",
      "lat": "-38.2200",
      "lng": "175.8720",
      "population": "13650"
    },
    {
      "label": "Mosgiel",
      "lat": "-45.8750",
      "lng": "170.3480",
      "population": "13400"
    },
    {
      "label": "Te Awamutu",
      "lat": "-38.0083",
      "lng": "175.3250",
      "population": "12400"
    },
    {
      "label": "Waikanae",
      "lat": "-40.8760",
      "lng": "175.0640",
      "population": "12100"
    },
    {
      "label": "Hawera",
      "lat": "-39.5833",
      "lng": "174.2833",
      "population": "9810"
    },
    {
      "label": "Glencoe",
      "lat": "-46.1920",
      "lng": "168.6460",
      "population": "9750"
    },
    {
      "label": "Waiuku",
      "lat": "-37.2500",
      "lng": "174.7333",
      "population": "9660"
    },
    {
      "label": "Wanaka",
      "lat": "-44.7081",
      "lng": "169.1239",
      "population": "8900"
    },
    {
      "label": "Te Puke",
      "lat": "-37.7833",
      "lng": "176.3167",
      "population": "8230"
    },
    {
      "label": "Greymouth",
      "lat": "-42.4667",
      "lng": "171.2000",
      "population": "8160"
    },
    {
      "label": "Matamata",
      "lat": "-37.8097",
      "lng": "175.7733",
      "population": "7910"
    },
    {
      "label": "Thames",
      "lat": "-37.1383",
      "lng": "175.5375",
      "population": "7680"
    },
    {
      "label": "Kawerau",
      "lat": "-38.0847",
      "lng": "176.6996",
      "population": "7080"
    },
    {
      "label": "Waitara",
      "lat": "-38.9958",
      "lng": "174.2331",
      "population": "7040"
    },
    {
      "label": "Kerikeri",
      "lat": "-35.2244",
      "lng": "173.9514",
      "population": "6960"
    },
    {
      "label": "Ngaruawahia",
      "lat": "-37.6680",
      "lng": "175.1470",
      "population": "6580"
    },
    {
      "label": "Lincoln",
      "lat": "-43.6400",
      "lng": "172.4860",
      "population": "6030"
    },
    {
      "label": "Kaitaia",
      "lat": "-35.1125",
      "lng": "173.2628",
      "population": "5870"
    },
    {
      "label": "Stratford",
      "lat": "-39.3410",
      "lng": "174.2840",
      "population": "5740"
    },
    {
      "label": "Alexandra",
      "lat": "-45.2492",
      "lng": "169.3797",
      "population": "5510"
    },
    {
      "label": "Cromwell",
      "lat": "-45.0400",
      "lng": "169.2000",
      "population": "5440"
    },
    {
      "label": "Warkworth",
      "lat": "-36.4000",
      "lng": "174.6667",
      "population": "5320"
    },
    {
      "label": "Waihi",
      "lat": "-37.3930",
      "lng": "175.8320",
      "population": "5310"
    },
    {
      "label": "Marton",
      "lat": "-40.0692",
      "lng": "175.3783",
      "population": "5200"
    },
    {
      "label": "Dargaville",
      "lat": "-35.9333",
      "lng": "173.8833",
      "population": "5170"
    },
    {
      "label": "Whitianga",
      "lat": "-36.8331",
      "lng": "175.6997",
      "population": "5130"
    },
    {
      "label": "Tuakau",
      "lat": "-37.2667",
      "lng": "174.9500",
      "population": "5090"
    },
    {
      "label": "Katikati",
      "lat": "-37.5500",
      "lng": "175.9167",
      "population": "4750"
    },
    {
      "label": "Westport",
      "lat": "-41.7500",
      "lng": "171.6000",
      "population": "4660"
    },
    {
      "label": "Opotiki",
      "lat": "-38.0070",
      "lng": "177.2870",
      "population": "4530"
    },
    {
      "label": "Kaikohe",
      "lat": "-35.4075",
      "lng": "173.7997",
      "population": "4490"
    },
    {
      "label": "Otaki",
      "lat": "-40.7583",
      "lng": "175.1500",
      "population": "4490"
    },
    {
      "label": "Te Aroha",
      "lat": "-37.5388",
      "lng": "175.7091",
      "population": "4470"
    },
    {
      "label": "Prebbleton",
      "lat": "-43.5783",
      "lng": "172.5133",
      "population": "4350"
    },
    {
      "label": "Wairoa",
      "lat": "-39.0420",
      "lng": "177.4230",
      "population": "4330"
    },
    {
      "label": "Paeroa",
      "lat": "-37.3667",
      "lng": "175.6670",
      "population": "4310"
    },
    {
      "label": "Whangamata",
      "lat": "-37.2100",
      "lng": "175.8740",
      "population": "4060"
    },
    {
      "label": "Balclutha",
      "lat": "-46.2417",
      "lng": "169.7333",
      "population": "4060"
    },
    {
      "label": "Snells Beach",
      "lat": "-36.4222",
      "lng": "174.7275",
      "population": "3540"
    },
    {
      "label": "Turangi",
      "lat": "-38.9890",
      "lng": "175.8100",
      "population": "3320"
    },
    {
      "label": "Raglan",
      "lat": "-37.8000",
      "lng": "174.8710",
      "population": "3280"
    },
    {
      "label": "Ohope Beach",
      "lat": "-37.9720",
      "lng": "177.0570",
      "population": "3180"
    },
    {
      "label": "Helensville",
      "lat": "-36.6797",
      "lng": "174.4494",
      "population": "3150"
    },
    {
      "label": "Foxton",
      "lat": "-40.4717",
      "lng": "175.2858",
      "population": "3130"
    },
    {
      "label": "Woodend",
      "lat": "-43.3167",
      "lng": "172.6670",
      "population": "3100"
    },
    {
      "label": "Hokitika",
      "lat": "-42.7167",
      "lng": "170.9667",
      "population": "3090"
    },
    {
      "label": "Ashhurst",
      "lat": "-40.3000",
      "lng": "175.7500",
      "population": "2990"
    },
    {
      "label": "Arrowtown",
      "lat": "-44.9425",
      "lng": "168.8358",
      "population": "2950"
    },
    {
      "label": "Renwick",
      "lat": "-41.5099",
      "lng": "173.8282",
      "population": "2928"
    },
    {
      "label": "Darfield",
      "lat": "-43.4880",
      "lng": "172.1090",
      "population": "2900"
    },
    {
      "label": "Otorohanga",
      "lat": "-38.1833",
      "lng": "175.2000",
      "population": "2890"
    },
    {
      "label": "Omokoroa",
      "lat": "-37.6650",
      "lng": "176.0350",
      "population": "2820"
    },
    {
      "label": "Kihikihi",
      "lat": "-38.0333",
      "lng": "175.3500",
      "population": "2740"
    },
    {
      "label": "Wakefield",
      "lat": "-41.4044",
      "lng": "173.0483",
      "population": "2570"
    },
    {
      "label": "Pahiatua",
      "lat": "-40.4533",
      "lng": "175.8408",
      "population": "2550"
    },
    {
      "label": "Winton",
      "lat": "-46.1430",
      "lng": "168.3240",
      "population": "2380"
    },
    {
      "label": "Ruakaka",
      "lat": "-35.9084",
      "lng": "174.4596",
      "population": "2360"
    },
    {
      "label": "Maraetai",
      "lat": "-36.8830",
      "lng": "175.0430",
      "population": "2290"
    },
    {
      "label": "Te Anau",
      "lat": "-45.4167",
      "lng": "167.7167",
      "population": "2250"
    },
    {
      "label": "Clive",
      "lat": "-39.5833",
      "lng": "176.9167",
      "population": "2210"
    },
    {
      "label": "Kaikoura",
      "lat": "-42.4000",
      "lng": "173.6814",
      "population": "2210"
    },
    {
      "label": "Oxford",
      "lat": "-43.2980",
      "lng": "172.1880",
      "population": "2200"
    },
    {
      "label": "Pokeno",
      "lat": "-37.2430",
      "lng": "175.0180",
      "population": "2170"
    },
    {
      "label": "Wellsford",
      "lat": "-36.2958",
      "lng": "174.5233",
      "population": "2120"
    },
    {
      "label": "Waitangi",
      "lat": "-43.9510",
      "lng": "-176.5610",
      "population": "300"
    }
  ]