import _ from 'lodash';
import moment from 'moment';
import Footer from '../components/Footer';

import React,
{
  useEffect,
  useState,
  useContext,
} from 'react';

import {
  database
} from 'firebase';

import {
  Application,
  Pagination,
  Button,
  Spinner
} from 'react-rainbow-components';

import {
  View,
  Text,
  Image,
  TouchableOpacity,
  StyleSheet,
  Dimensions,
  ScrollView
} from 'react-native';

import { UserContext } from '../context/UserContext';

import WebsiteHeader from '../components/WebsiteHeader';
import { Linking } from 'expo';
import AboutUs from '../components/AboutUs';

const deviceWidth = Dimensions.get("window").width;
const deviceHeight = Dimensions.get("screen").height;

const shadow = { 
  shadowColor: "#b7eae6",
  shadowOffset: {
    width: 0,
    height: 3,
  },
  shadowOpacity: 0.8,
  shadowRadius: 18,
  
  elevation: 14,
}

export default function InformationalScreen({ navProp }) {
  const [userInfo, setUserInfo] = useContext(UserContext);
  const [page, setPage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchScreenDetails();
  }, [setPage]);

  const fetchScreenDetails = async () => {
    setLoading(true);

    Linking
    .getInitialURL()
    .then((url: any) => {
      var searchValue = Linking.parse(url).queryParams?.page;
      setPage(searchValue);
      setLoading(false);
    });
  }

  return (
    <View style={styles.container}>
      <ScrollView
        scrollEventThrottle={400}
        contentContainerStyle={{ width: '100%', height: 100 }}
        showsHorizontalScrollIndicator={false}>
        <WebsiteHeader navProp={navProp} />
        {page === 'aboutus' && <AboutUs />}
        <Footer />
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: 100,
    backgroundColor: '#e3f6f5'
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  information: {
    width: '100%',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  }
});
