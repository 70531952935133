import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import * as Linking from 'expo-linking';

import { Lookup, Application } from 'react-rainbow-components';
import { Dimensions, View } from 'react-native';

import { NZCities } from '../constants/NZCities';

const deviceWidth = Dimensions.get("window").width;
const deviceHeight = Dimensions.get("window").height;

export default function SearchListings({ width, screenname }) {
    const [options, setOptions] = useState<any>(null);
    const [value, setValue] = useState<any>();

    useEffect(() => {
        if(value?.label) {
            openSearchResults(value?.label);
        }
    })

    function filter(query, options) {
        if (query) {
            return options.filter(item => {
                const regex = new RegExp(query, 'i');
                return regex.test(item.label);
            });
        }
        return [];
    }

    const openSearchResults = (searchValue: any) => {
        let redirectUrl = Linking.makeUrl('listings', { search: searchValue });
        Linking.openURL(redirectUrl);
    }

    function search(typedValue: string | any[]) {
        if (options && value && typedValue.length > value.length) {
            setOptions(filter(typedValue, options));
            setValue(typedValue);
        } else if (typedValue) {
            setValue(typedValue);
            setOptions(filter(typedValue, NZCities));
        } else {
            setValue('');
            setOptions(null);
        }
    }

    return(
        <View 
            style={{ 
                flexDirection: 'row', 
                alignItems: 'center', 
                alignSelf: 'center', 
                height: deviceWidth < 505 ? 40 : 60, 
                width: width, 
                position: 'relative',
                zIndex: 1,
                marginVertical: screenname === 'search' ? 20 : 0 
            }}>
            <Application style={{ width: '100%' }} theme={{ rainbow: { palette: { brand: '#fed800' } } }}>
                <Lookup
                    id="Search"
                    placeholder="Search for accommodations"
                    options={options}
                    value={value}
                    onChange={(typedValue: any) => setValue(typedValue)}
                    onSearch={search}
                    style={{ width: '100%', maxWidth: 700 }}
                />
            </Application>
        </View>
    );
}