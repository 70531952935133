import * as Linking from 'expo-linking';

export default {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Root: {
        screens: {
          loading: {
            screens: {
              LoadingScreen: 'loading',
            },
          },
          login: {
            screens: {
              LoginScreen: 'login',
            },
          },
          home: {
            screens: {
              Home: 'home',
            },
          },
          profile: {
            screens: {
              Profile: 'profile',
            },
          },
          listings: {
            screens: {
              ListingsScreen: 'listings',
            },
          },
          createlisting: {
            screens: {
              CreateListingScreen: 'createlisting',
            },
          },
          createflatmate: {
            screens: {
              CreateFlatmateScreen: 'createflatmate',
            },
          },
          information: {
            screens: {
              InformationalScreen: 'information',
            },
          },
          editlisting: {
            screens: {
              EditListingScreen: 'editlisting',
            },
          },
          editflatmatelisting: {
            screens: {
              EditFlatmateListingScreen: 'editflatmatelisting',
            },
          },
          viewlisting: {
            screens: {
              ViewListingScreen: 'viewlisting',
            },
          },
          viewflatmatelisting: {
            screens: {
              ViewFlatmateListingScreen: 'viewflatmatelisting',
            },
          },
          messages: {
            screens: {
              Messages: 'messages',
            },
          },
          favourites: {
            screens: {
              Favourites: 'favourites',
            },
          },
        },
      },
      NotFound: '*',
    },
  },
};
