import * as React from 'react';

import { Platform, View, Text, Image, TouchableOpacity, TextInput, StyleSheet, Dimensions, ActivityIndicator, Button } from 'react-native';
import { Hoverable } from 'react-native-web-hover'

const deviceWidth = Dimensions.get("window").width;
const deviceHeight = Dimensions.get("screen").height;

export default function LoadingScreen({ navProp }) {
  return (
    <View style={styles.container}>
      <View style={{ flexDirection: deviceWidth < 1000 ? 'column' : 'row', width: '100%', maxWidth: 1200, alignSelf: "center", justifyContent: 'space-between', alignItems: 'center', marginTop: 30 }}>
        <Text>Login Screen</Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  information: { 
    width: deviceWidth < 1000 ? '90%' : '48.5%', 
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  }
});
