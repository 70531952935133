import _ from 'lodash';
import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { MapMarker, GMap } from 'react-rainbow-components';
import { PresenceMap } from 'react-rainbow-components';
import * as Location from 'expo-location';

import { Platform, View, Text, Image, TouchableOpacity, TextInput, StyleSheet, Dimensions, ActivityIndicator } from 'react-native';

const deviceWidth = Dimensions.get("screen").width;
const deviceHeight = Dimensions.get("screen").height;

const iconPath = "M 100, 100 m -75, 0a 75,75 0 1,0 150,0a 75,75 0 1,0 -150,0";

export default function WebNavigation({ addresses }) {
    const [userInfo, setUserInfo] = useContext(UserContext);
    const [location, setLocation] = useState<any>([]);

    const markers = _.map(location, loc => {
      return {
        position: {
          lat: loc?.latitude,
          lng: loc?.longitude,
        },
        icon: {
            path: iconPath,
            fillColor: '#ff8c00',
            fillOpacity: 0.4,
            scale: 1,
            strokeColor: 'transparent',
            strokeWeight: 0,
        },
      }
    })

    useEffect(() => {
      getLocation();
    }, [setLocation]);

    const getLocation = async() => {
      if (!addresses) return;

      Location.setGoogleApiKey('AIzaSyCmEe_VHAD-hf9ua8vnHk0dky5gf2yLQoE');

      _.map(addresses, async address => {
        const getLocationCoords = await Location.geocodeAsync(address?.label);
        const loc = getLocationCoords[0];
        setLocation((location: any) =>  { return [...location, loc]});
      });
    }

    return(
      <View style={{ width: '100%', borderRadius: 10, overflow: 'hidden', marginTop: 15, marginBottom: 15 }}>
          {location[0]?.latitude && 
          <PresenceMap
            apiKey='AIzaSyCmEe_VHAD-hf9ua8vnHk0dky5gf2yLQoE'
            zoom={5}
            markers={markers || []}
            center={{
              lat: location[0]?.latitude,
              lng: location[0]?.longitude
            }}
            onMarkerClick={() => {}} />}
            <View style={{ padding: 20, backgroundColor: '#f2f2f2', borderBottomEndRadius: 10, borderBottomStartRadius: 10, }}>
              <Text style={{ fontSize: 16, color: 'gray' }}>Listing is located in this area</Text>
              <Text style={{ fontSize: 12 }}>{addresses[0]?.value?.structured_formatting?.secondary_text}</Text>
            </View>
      </View>
    );
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      fontSize: 20,
      fontWeight: 'bold',
    },
    separator: {
      backgroundColor: '#eee',
      marginVertical: 30,
      height: 1,
      width: '80%',
      alignSelf: 'center'
    },
    inputBox: {
        marginTop: 10,
        height: 50,
        width: '100%',
        alignSelf: 'center',
        paddingLeft: 10,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: 'gray',
        fontSize: 17,
     }
  });