import _ from 'lodash';
import moment from 'moment';

import * as MailComposer from 'expo-mail-composer';

import React, { useEffect, useState } from 'react';
import { Linking } from 'expo';

import { Platform, View, Text, Image, TouchableOpacity, TextInput, StyleSheet, Dimensions, ActivityIndicator } from 'react-native';
import { Button } from 'react-rainbow-components';

const deviceWidth = Dimensions.get("window").width;
const deviceHeight = Dimensions.get("screen").height;

const openURL = (screenName) => {
    let redirectUrl = Linking.makeUrl('information', { page: screenName });
    Linking.openURL(redirectUrl);
}

const contactUs = () => {
    MailComposer.composeAsync({
        recipients: 
        ['info@pharoscompany.com'],
        subject: 'flatting.co.nz - Enquiry',
        body: '',
      });
}

const Footer = () => {
    return (
        <View style={{ width: deviceWidth, backgroundColor: '#231f20', padding: deviceWidth < 505 ? 10 : 20 }}>
            <View style={{ maxWidth: 1200, width: '100%', alignSelf: 'center' }}>
                <Image source={require('../assets/icons/flatting-logo-bw.svg')} style={{ width: deviceWidth < 505 ? 100 : 180, height:  deviceWidth < 505 ? 65 : 80, resizeMode: 'contain' }} />
                <View style={{ flexDirection: deviceWidth < 700 ? 'column' : 'row' }}>
                    <Text style={{ maxWidth: 450, padding:  deviceWidth < 505 ? 10 : 20, color: 'white', fontFamily: 'pt-regular', fontSize: deviceWidth < 505 ? 14 : 18 }}>
                        flatting.co.nz is a New Zealand owned and operated peer to peer 
                        listing site for those looking for shared homes or those looking 
                        for a flatmate. Simply create a listing, search and connect.
                    </Text>
                    <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                        <View style={{ paddingHorizontal: deviceWidth < 505 ? 10 : 40 }}>
                            <Button onClick={() => openURL('aboutus')} label='About Us' variant='inverse' style={{ fontSize:  deviceWidth < 505 ? 12 : 16 }} />
                            <Button label='How it works' variant='inverse' style={{ fontSize:  deviceWidth < 505 ? 12 : 16 }} />
                            <Button label='Information' variant='inverse' style={{ fontSize:  deviceWidth < 505 ? 12 : 16 }} />
                        </View>
                        <View style={{ paddingHorizontal: deviceWidth < 505 ? 10 : 40 }}>
                            <Button label='Privacy Policy' variant='inverse' style={{ fontSize:  deviceWidth < 505 ? 12 : 16 }} />
                            <Button label='Terms & Conditions' variant='inverse' style={{ fontSize:  deviceWidth < 505 ? 12 : 16 }} />
                            <Button onClick={contactUs} label='Contact Us' variant='inverse' style={{ fontSize:  deviceWidth < 505 ? 12 : 16 }} />
                        </View>
                    </View>
                </View>
                <View style={{ padding: 20, paddingTop: 40 }}>
                    <Text style={{ fontFamily: 'pt-regular', color: 'white', fontStyle: 'italic', paddingBottom: 10 }}>flatting.co.nz is proudly New Zealand owned and operated website</Text>
                    <Text style={{ fontFamily: 'pt-regular', color: 'white', fontStyle: 'italic' }}>© Copyright 2019-2021</Text>
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({

});

export default Footer;