import _ from 'lodash';;
import moment from 'moment'
import React, { useEffect, useState, useContext, useRef, memo } from 'react';
import { database } from 'firebase';

import { UserContext } from '../context/UserContext';

import { View, Text, Image, Dimensions, ScrollView, TouchableOpacity } from 'react-native';
import { Textarea, Button, Application, Spinner,  ButtonMenu, MenuItem, Modal } from 'react-rainbow-components';

import { firebaseMessagesDB } from '../services/firebase';
import WebsiteHeader from '../components/WebsiteHeader';
import Footer from '../components/Footer';

const deviceWidth = Dimensions.get("window").width;
const deviceHeight = Dimensions.get("window").height;

const emptyUser = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAIAAABvFaqvAAAABmJLR0QA/wD/AP+gvaeTAAACbElEQVQ4ja2VzW6rMBCFhyjGYInIIKcoClm0qKv2/Z+i26iLqs0itAI5EW6pjE0r+S7myiHpz72LzNJ4vjnHYw/B3d0dnCMmZ6EAwPTb1SAIOOecc8ZYGIYAMAyD1loppZRyzv0XKE3T5XJJKR0vRlEURVGWZdbaqqqUUr+BgiBYLpd5ngNA3/e73a7rOmstAFBKkyQRQsRxXJZl0zRVVf0IQopzbrvdSim9FuT2fS+lFEKsVissNmYdQGmaIuXh4aHrOkRcXV3FcQwAWuvNZmOMkVIaY66vr/M8f39/9x4nY1MAsN1ukQIASBmGYRgGxtjl5SWud12HWoqiCILgCMQ5p5SieO8IKev1er1eIwttAoCUsu97Sinn/BQEALvdDn4N33jn3H6/94mHM2KMAcDb25vPMcZorRljNzc3ABCGodYaO4iBmzHxoIgQAgAfHx/j+pvNRmsdhiFSnp6exl+HYfCJcNL+kytrjLm/v8dzMcb8ZPNIEWrB13ASxpivFL/Zm/irSGsdRVGSJJgznU7TNE2ShDHmXWutu65r2/bz8xMAZrMZJh6BlFJZls3n8/1+v1gsLi4uJpOjwUAppZSmaVoURdM0dV0LITDxFGStjeP49vaWEILdVUr1fY/iCSFxHHPOsyxbLBZCCEKItfYU5JyrqqosS0KI1vrx8RGb4sNai2kvLy9lWWLXq6ryR37Qr5RqmgYAsLK/++PAOYWvr67r8TA5aj++oDzPV6vVfD6XUo7HyGw2E0Lgbajr+vn5+ajG15nNOS+K4mSwjT3+e7B5j6+vr2cYtQDgnGvbtm3bb79+G2f7i/wBBgF9qEzvmnMAAAAASUVORK5CYII=";

const shadow = {
    shadowColor: "#b7eae6",
    shadowOffset: {
        width: 0,
        height: 3,
    },
    shadowOpacity: deviceWidth < 650 ? 0.1 : 1,
    shadowRadius: 20,
}

const textShadow = {
    shadowColor: "#000",
    shadowOffset: {
        width: 1,
        height: 3,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,
}

function MessagesScreen({ navProp }) {
    const [userInfo, setUserInfo] = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [deleteModal, setDeleteModal] = useState(false);
    const [messages, setMessages] = useState<any[]>([]);
    const [selected, setSelected] = useState<any>();
    const [message, setMessage] = useState<string>();

    const scrollViewRef = useRef<any>(null);

    useEffect(() => {
        if(!userInfo.uid) {
            setTimeout(() => {
                setLoading(false);
            }, 5000);
        } else {
            fetchMessages();
        }
    }, [userInfo.uid]);

    const fetchMessages = async() => {
        var messagesDB = database(firebaseMessagesDB);

        database()
            .ref(`users/${userInfo.uid}/messages`)
            .on('child_removed', (snapshot) => {
                setLoading(true);
                setMessages(messages => {
                    let findChangeIndex = _.findIndex(messages, (message: any) => message?.messageId === snapshot.val()?.messageId);

                    messages.splice(findChangeIndex, 1);
                    return [...messages];
                })
                setLoading(false);
            });

        database()
            .ref(`users/${userInfo.uid}/messages`)
            .on('value', snapshot => {
                let messageKeys = snapshot.val();

                _.map(messageKeys, (messageKey: any) => {
                    let messageId = messageKey.messageId;

                    messagesDB
                        .ref(`messages/${messageId}`)
                        .on('value', (snapshot) => {
                            let incomingMessages = {
                                ...snapshot.val(),
                                notifications: messageKey.notifications,
                            };

                            setMessages(messages => {
                                let findChangeIndex = _.findIndex(messages, (message: any) => message?.messageId === snapshot.val()?.messageId);
                                let newMessages = _.sortBy(messages, (message: { lastUpdated: any; }) => moment.unix(message.lastUpdated));

                                if(findChangeIndex > -1) {
                                    newMessages[findChangeIndex] = incomingMessages;
                                    return [...newMessages];
                                } else {
                                    return [...messages, incomingMessages];
                                }
                            });
                            setLoading(false);
                        });
                });
        });
    }

    const selectMessages = async(index: any, messageId: any, notificationCount: any) => {
        setSelected(index);
        setMessages(messages => {
            messages[index] = {
                ...messages[index],
                notifications: 0
        }
            return [...messages]
        })
        setMessage('');
    }

    const sendMessage = async() => {
        setLoading(true);
        var mainDB = database();
        var messagesDB = database(firebaseMessagesDB);
        
        let timeStamp = moment().unix();
        let selectedMessage = messages[selected];
        let receiverId = userInfo.uid !== messages[selected].owner.ownerId ? messages[selected].owner.ownerId : messages[selected].enquirer.enquirerId;
        let messageId = selectedMessage.messageId;
        
        const data = {
            id: userInfo.uid,
            from: userInfo.firstName,
            photo: userInfo.photoUrl ? userInfo.photoUrl : emptyUser,
            message: message,
        }

        const updateData = {
            [`messages/${messageId}/messages/${timeStamp}`] : data,
            [`messages/${messageId}/lastUpdated`] : timeStamp,
        }

        await messagesDB.ref().update(updateData);

        setMessage('');
        setLoading(false);
    }

    const deleteMessages = async() => {
        setLoading(true);
        var mainDB = database();
        var messagesDB = database(firebaseMessagesDB);

        let selectedMessage = messages[selected];
        let messageId = selectedMessage.messageId;
        let ownerId = selectedMessage.owner.ownerId;
        let enquirerId = selectedMessage.enquirer.enquirerId;

        const deleteMessageRef = {
            [`users/${ownerId}/messages/${messageId}`] : null,
            [`users/${enquirerId}/messages/${messageId}`] : null,
        }

        await mainDB.ref().update(deleteMessageRef);

        const deleteMessages = {
            [`messages/${messageId}`] : null,
        }

        await messagesDB.ref().update(deleteMessages);
        setLoading(false);
        setSelected(null);
        setDeleteModal(false);
    }

    return (
        <View style={{ flex: 1, backgroundColor: '#e3f6f5' }}>
            <ScrollView 
                style={{ height: 100 }}
                nestedScrollEnabled
                contentContainerStyle={{ width: '100%', height: '100%', maxHeight: deviceWidth < 700 ? '' : 700 }}
                showsHorizontalScrollIndicator={false}>
            <WebsiteHeader navProp={navProp} />
            <View style={{ paddingTop: 20, paddingBottom: deviceWidth < 800 ? 20 : 10, maxWidth: 1200, width: '100%', alignSelf: 'center', flexDirection: 'row', alignItems: 'center', justifyContent: deviceWidth < 800 ? 'center' : 'flex-start' }}>
                <Image source={require('../assets/icons/messages.png')} style={{ height: 45, width: 45 }} />
                <Text style={{ fontFamily: 'pacifico-regular', fontSize: deviceWidth < 505 ? 25 : 35,  }}> Messages</Text>
            </View>
            <Application theme={{ rainbow: { palette: { brand: '#fed800' } } }} style={{ width: '100%' }}>
                <Modal
                    id="delete-modal"
                    isOpen={deleteModal}
                    onRequestClose={() => setDeleteModal(false)}
                    title="Are you sure?"
                    footer={
                        <div className="rainbow-flex rainbow-justify_end">
                            <Button
                                className="rainbow-m-right_large"
                                label="Cancel"
                                variant="base"
                                onClick={() => setDeleteModal(false)}
                            />
                            <Button label="Delete" variant="destructive" onClick={deleteMessages} />
                        </div>
                    }
                >
                    <Text>Deleting this conversation is a permanent action and cannot be undone. The messages will be removed for you and the other user.</Text>
                </Modal>
            </Application>
                {!userInfo.uid && <View style={{ width: '100%', maxWidth: 1200, alignSelf: 'center', alignItems: 'center', padding: 10, marginTop: deviceWidth < 650 ? 0 :20, borderRadius: deviceWidth < 650 ? 0 : 10, backgroundColor: '#353535' }}>
                    <Text style={{ color: 'white', fontWeight: 'bold' }}>Please login to view your messages</Text>    
                </View>}
                <View style={{ width: '100%', height: '100%', minHeight: 650, maxWidth: 1200, alignSelf: 'center', flexDirection: deviceWidth < 650 ? 'column' : 'row', marginTop: deviceWidth < 650 ? 10 : 20, marginBottom: deviceWidth < 650 ? 10 : 40, }}>
                    <View style={[shadow, { flex: 1, minHeight: 90, backgroundColor: 'white', borderRadius: deviceWidth < 650 ? 0 : 10, padding: 10, paddingBottom: 10, marginRight: deviceWidth < 650 ? 0 : 20, marginBottom: deviceWidth < 650 ? 10 : 0 }]}>
                        <View style={{ flex: 1 }}>
                            <Text style={{ fontSize: deviceWidth < 650 ? 16 : 22, fontFamily: 'pacifico-regular', padding: deviceWidth < 650 ? 10 : 20 }}>All Messages</Text>
                            <ScrollView showsHorizontalScrollIndicator={false} horizontal={deviceWidth < 650} contentContainerStyle={{ height: deviceWidth < 650 ? 70 : '100%' }}>
                                {loading && <Application theme={{ rainbow: { palette: { brand: '#fed800' } } }} style={{ width: deviceWidth < 650 ? deviceWidth : '100%' }}><Spinner variant="brand" size="large" style={{ marginTop: deviceWidth < 650 ? 0 : 50 }} /></Application>}
                                {(_.isEmpty(messages) && !loading) && <Text style={{ paddingLeft: deviceWidth < 650 ? 20 : 0, alignSelf: 'center' }}>No Messages</Text>}
                                {!_.isEmpty(messages) && _.map(_.compact(messages), (message: any, index: any) => {
                                    let userImage = userInfo?.uid !== message?.owner?.ownerId ? message?.owner?.ownerPhoto : message?.enquirer?.enquirerPhoto;
                                    let userName = userInfo?.uid !== message?.owner?.ownerId ? message?.owner?.ownerFirstName : message?.enquirer?.enquirerFirstName;

                                    return(
                                        <TouchableOpacity 
                                            onPress={() => selectMessages(index, message?.messageId, message?.notifications)}
                                            key={message?.listingId} 
                                            style={{ 
                                                padding: deviceWidth < 650 ? 5 : 15,
                                                flexDirection: 'row', 
                                                alignItems: 'center', 
                                                borderBottomWidth: deviceWidth < 650 ? 0 : 1,
                                                borderColor: '#f2f2f2',
                                                borderRadius: deviceWidth < 650 ? 40 : 10,
                                                backgroundColor: (selected === index && deviceWidth > 650) ? '#f2f2f2' : 'white'
                                            }}>
                                            {message?.notifications === 1 && <Text 
                                                style={{ 
                                                    padding: 5, 
                                                    borderRadius: 20, 
                                                    backgroundColor: 'green', 
                                                    position: 'absolute', 
                                                    zIndex: 1, 
                                                    left: deviceWidth < 605 ? 25 : 40, 
                                                    top: 5, 
                                                    color: 'white', 
                                                    fontSize: 12 
                                                }}>New</Text>}
                                            <Image 
                                                source={{ uri: userImage }} 
                                                style={{ 
                                                    width: deviceWidth < 650 ? 40 : 50, 
                                                    height: deviceWidth < 650 ? 40 : 50, 
                                                    borderRadius: deviceWidth < 650 ? 40 : 10, 
                                                    alignSelf: 'center', 
                                                    backgroundColor: '#f2f2f2', 
                                                    borderColor: (selected === index && deviceWidth < 650) ? '#fed800' : 'white', 
                                                    borderWidth: (selected === index && deviceWidth < 650) ? 3 : 0 }} />
                                            {deviceWidth > 650 && <View style={{ paddingLeft: 15, paddingRight: 15, justifyContent: 'center', width: '100%' }}>
                                                <Text style={{ fontSize: 16, fontWeight: 'bold', color: '#252525' }} numberOfLines={1}>{userName}</Text>
                                                <Text style={{ fontSize: 12, maxWidth: '85%', color: 'gray', lineHeight: 17 }} numberOfLines={2}>{message?.location}</Text>
                                                <Text style={{ fontSize: 12, maxWidth: '85%', color: 'gray', lineHeight: 17 }} numberOfLines={1}>{message?.type}</Text>
                                            </View>}
                                        </TouchableOpacity>
                                    )
                                })}
                            {(!loading && deviceWidth > 650) && <Text style={{ fontSize: 14, color: 'gray', width: '100%', textAlign: 'center', padding: 20 }}>End of messages...</Text>}
                            </ScrollView>
                        </View>
                    </View>
                    <View style={[shadow, { flex: deviceWidth < 650 ? 4 : 3, backgroundColor: 'white', borderRadius: deviceWidth < 650 ? 0 : 10, overflow: 'hidden', alignItems: 'center', justifyContent: 'center' }]}>
                        {(_.isNil(selected) || _.isEmpty(_.compact(messages)) || _.isNull(selected)) && <View style={{ width: '100%', alignItems: 'center' }}>
                            <Image source={require('../assets/images/no-messages.svg')} style={{ width: '40%', height: deviceWidth < 650 ? 100 : 300, resizeMode: 'contain' }} />
                            <Text style={{ paddingTop: 40, fontSize: 25, fontFamily: 'pacifico-regular' }}>No messages found</Text>
                            <Text style={{ paddingTop: 10, fontSize: 16, color: 'gray' }}>Looks like you're all caught up</Text>
                        </View>}
                        {!_.isNil(messages[selected]?.messages) && <View style={{ flex: 1, width: '100%', height: 100 }}>
                            <View style={{ width: '100%', flexDirection: 'row', borderBottomWidth: 1, borderColor: '#f2f2f2', padding: 10, paddingBottom: 20, alignItems: 'center' }}>
                                <Image source={{ uri: messages[selected]?.owner.ownerPhoto}} style={{ width: 40, height: 40, borderRadius: 30, alignSelf: 'center', borderWidth: 2, borderColor: 'white', backgroundColor: '#f2f2f2' }} />
                                <Image source={{ uri: messages[selected]?.enquirer.enquirerPhoto}} style={{ width: 40, height: 40, borderRadius: 30, alignSelf: 'center', borderWidth: 2, borderColor: 'white', backgroundColor: '#f2f2f2', left: -20, top: 10 }} />
                                <View style={{ justifyContent: 'center', top: 5, maxWidth: deviceWidth < 450 ? 200 : 650 }}>
                                    <Text style={{ fontSize: deviceWidth < 650 ? 14 : 16 }}>Conversation between <Text style={{ fontWeight: 'bold' }}>{messages[selected]?.owner.ownerFirstName}</Text> and <Text style={{ fontWeight: 'bold' }}>{messages[selected]?.enquirer.enquirerFirstName}</Text></Text>
                                    <Text style={{ fontSize: deviceWidth < 650 ? 12: 14, color: 'gray' }}>{messages[selected]?.location}</Text>
                                    <Text style={{ fontSize: deviceWidth < 650 ? 12: 14, color: 'gray' }}>{messages[selected]?.type}</Text>
                                </View>
                                <Application theme={{ rainbow: { palette: { brand: '#ff8c00' } } }} style={{ width: '100%' }}>
                                    <ButtonMenu
                                        menuAlignment="right"
                                        style={{ position: 'absolute', right: 10, top: 15 }}
                                        menuSize="x-small"
                                        buttonVariant="base"
                                        icon={<Image source={{ uri: "https://img.icons8.com/material-rounded/50/000000/menu-2.png" }} style={{ width: 20, height: 40, resizeMode: 'contain' }} />}
                                    >
                                        <MenuItem label="Delete Messages" onClick={() => setDeleteModal(true)} />
                                    </ButtonMenu>
                                </Application>
                            </View>
                            <ScrollView
                                ref={scrollViewRef}
                                style={{ width: '100%', backgroundColor: 'white', minHeight: 300 }}
                                showsVerticalScrollIndicator={false} 
                                onContentSizeChange={() => scrollViewRef.current.scrollToEnd({ animated: true })}>
                                {_.map(messages[selected]?.messages, (message: any, key: any) => {
                                    let viewer = userInfo?.uid === message?.id;

                                    return(
                                        <View key={key} style={{ width: '100%', flexDirection: viewer ? 'row-reverse' : 'row', alignItems: 'center', padding: 20 }}>
                                            <Image source={{ uri: message.photo}} style={{ width: 45, height: 45, borderRadius: 30, alignSelf: 'flex-start', backgroundColor: '#f2f2f2' }} />
                                            <Text 
                                                style={[textShadow, 
                                                    {   marginLeft: 10, 
                                                        marginRight: 10, 
                                                        color: viewer ? 'black' : 'black', 
                                                        fontFamily:'pt-regular', 
                                                        maxWidth: 400,
                                                        padding: 15, 
                                                        borderRadius: 5, 
                                                        backgroundColor: viewer ? '#f2f2f2' : '#fed800', 
                                                        fontSize: 16 
                                                    }]}>
                                                {message.message}
                                            </Text>
                                            {deviceWidth > 650 && <Text style={{ alignSelf: 'flex-end', marginLeft: 10, color: 'gray' }}>{moment.unix(key).fromNow()}</Text>}
                                        </View>
                                    )
                                })}
                            </ScrollView>
                            <Application theme={{ rainbow: { palette: { brand: '#fed800' } } }} style={{ width: '100%' }}>
                                <View style={{ width: '100%', borderTopWidth: 1, borderColor: '#f2f2f2', flexDirection: 'row', padding: 10, justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                        <Textarea
                                            id="message-input"
                                            rows={2}
                                            value={message}
                                            onChange={(e: any) => setMessage(e.target.value)}
                                            placeholder="Start typing your message..."
                                            style={{ width: '90%', marginRight: 10, borderRadius: 40 }}
                                        />
                                        <Button 
                                            label="Send"
                                            disabled={!message}
                                            variant="brand" 
                                            onClick={sendMessage} 
                                        />
                                </View>
                            </Application>
                        </View>}
                    </View>
                </View>
                <Footer />
            </ScrollView>
        </View>
    );
}

export default memo(MessagesScreen);