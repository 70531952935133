import { Ionicons } from '@expo/vector-icons';
import * as Font from 'expo-font';
import { Asset } from 'expo-asset';
import * as SplashScreen from 'expo-splash-screen';
import * as React from 'react';

export default function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = React.useState(false);

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHideAsync();

        // Load fonts
        await Font.loadAsync({
          ...Ionicons.font,
          'space-mono': require('../assets/fonts/SpaceMono-Regular.ttf'),
          'kaushan-regular': require('../assets/fonts/KaushanScript-Regular.ttf'),
          'pacifico-regular': require('../assets/fonts/Pacifico-Regular.ttf'),
          'pt-regular': require('../assets/fonts/PTSans-Regular.ttf'),
          'pt-bold': require('../assets/fonts/PTSans-Bold.ttf'),
        });

        await Asset.loadAsync(require('../assets/images/create-account.svg'));
        await Asset.loadAsync(require('../assets/images/bed.svg'));
        await Asset.loadAsync(require('../assets/images/favicon.png'));
        await Asset.loadAsync(require('../assets/images/icon.png'));
        await Asset.loadAsync(require('../assets/images/login.svg'));
        await Asset.loadAsync(require('../assets/images/no-listing.svg'));
        await Asset.loadAsync(require('../assets/images/no-messages.svg'));
        await Asset.loadAsync(require('../assets/images/searching.svg'));
        await Asset.loadAsync(require('../assets/images/splash.png'));
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
        SplashScreen.hideAsync();
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  return isLoadingComplete;
}
