import React, { useState, useEffect, memo } from 'react';
import { database, initializeApp, auth, analytics } from 'firebase/app';

import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import { UserContext } from './context/UserContext';
import { firebaseWebConfig } from './services/firebaseConfig';

import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';
import moment from 'moment';

initializeApp(firebaseWebConfig);
analytics();
interface UserProps {
  firstName: string,
  email: string,
  photoUrl: string,
  phoneNumber: string,
  phoneVerified: boolean,
  uid: string,
  lastOnline: number | null,
  loading: boolean
};

const initialUserState = {
  firstName: '',
  email: '',
  photoUrl: '',
  phoneNumber: 'Not Provided',
  phoneVerified: false,
  uid: '',
  lastOnline: moment().unix(),
  loading: true
}

function App() {
  const [userInfo, setUserInfo] = useState<UserProps>(initialUserState);

  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();

  useEffect(() => {
    getUserDetails();
  }, [setUserInfo]);

  const getUserDetails = () => {
    auth()
      .onAuthStateChanged(user => {
        if (user) {
          database()
            .ref(`users/${user.uid}`)
            .once('value', snapshot => {
              setUserInfo({
                firstName: snapshot.val()?.firstName,
                email: snapshot.val()?.email,
                phoneNumber: snapshot.val().phoneNumber ? snapshot.val().phoneNumber : 'Not Provided',
                phoneVerified: snapshot.val().phoneVerified ?  snapshot.val().phoneVerified : false,
                photoUrl: snapshot.val()?.photoUrl,
                uid: user.uid,
                lastOnline: snapshot.val()?.lastOnline,
                loading: false
              });
            });
        } else {
          setUserInfo({
            firstName: '',
            email: '',
            photoUrl: '',
            phoneNumber: '',
            phoneVerified: false,
            uid: '',
            lastOnline: moment().unix(),
            loading: false
          });
        }
      });
  }

  if (!isLoadingComplete) return null;
    return (
        <SafeAreaProvider style={{ overflow: 'hidden' }}>
          <UserContext.Provider value={[userInfo, setUserInfo]}>
            <Navigation colorScheme={colorScheme} />
            <StatusBar />
          </UserContext.Provider>
        </SafeAreaProvider>
    );
  }

export default memo(App);