import { Ionicons } from '@expo/vector-icons';
import { Platform } from 'react-native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import React, { useState } from 'react';
import { useContext } from 'react';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';

import HomeScreen from '../screens/HomeScreen';
import ProfileScreen from '../screens/ProfileScreen';
import ListingsScreen from '../screens/ListingsScreen';
import CreateListingScreen from '../screens/CreateListingScreen';
import EditListingScreen from '../screens/EditListingScreen';
import ViewListingScreen from '../screens/ViewListing';
import ViewFlatmateListingScreen from '../screens/ViewFlatmateListing';
import MessagesScreen from '../screens/MessagesScreen';
import FavouritesScreen from '../screens/FavouritesScreen';
import LoginScreen from '../screens/LoginScreen';
import LoadingScreen from '../screens/LoadingScreen';
import CreateFlatmateScreen from '../screens/CreateFlatmateScreen';
import InformationalScreen from '../screens/InformationalScreen';
import WebsiteHeader from '../components/WebsiteHeader';

import { BottomTabParamList, HomeParamList, ProfileParamList, CreateListingParamList, LoadingParamList, LoginParamList, ViewListingParamList, MessagesParamList, EditListingParamList, ListingsParamList, FavouritesParamList, CreateFlatmateParamList, InformationalParamList, EditFlatmateListingParamList, ViewFlatemateListingParamList } from '../types';
import EditFlatmateListingScreen from '../screens/EditFlatmateListingScreen';

const BottomTab = createBottomTabNavigator<BottomTabParamList>();

export default function BottomTabNavigator() {
  const colorScheme = useColorScheme();

  return (
    <BottomTab.Navigator
      initialRouteName="home"
      tabBarOptions={{ activeTintColor: Colors[colorScheme].tint }}>
      <BottomTab.Screen
        name="loading"
        component={LoadingNavigator}
        options={{
          tabBarIcon: ({ color }) => <TabBarIcon name="ios-code" color={color} />,
          tabBarVisible: Platform.OS === "web" ? false : true,
        }}
      />
      <BottomTab.Screen
      name="login"
      component={LoginNavigator}
      options={{
        tabBarIcon: ({ color }) => <TabBarIcon name="ios-code" color={color} />,
        tabBarVisible: Platform.OS === "web" ? false : true,
      }}
    />
      <BottomTab.Screen
        name="home"
        component={HomeNavigator}
        options={{
          tabBarIcon: ({ color }) => <TabBarIcon name="ios-code" color={color} />,
          tabBarVisible: Platform.OS === "web" ? false : true,
        }}
      />
      <BottomTab.Screen
        name="profile"
        component={ProfileNavigator}
        options={{
          tabBarIcon: ({ focused, color, size }) => <TabBarIcon name="ios-code" color={color} />,
          tabBarVisible: Platform.OS === "web" ? false : true,
        }}
      />
     <BottomTab.Screen
        name="listings"
        component={ListingsNavigator}
        options={{
          tabBarIcon: ({ color }) => <TabBarIcon name="ios-code" color={color} />,
          tabBarVisible: Platform.OS === "web" ? false : true,
        }}
      />
      <BottomTab.Screen
        name="viewflatmatelisting"
        component={ViewFlatmateListingsNavigator}
        options={{
          tabBarIcon: ({ color }) => <TabBarIcon name="ios-code" color={color} />,
          tabBarVisible: Platform.OS === "web" ? false : true,
        }}
      />
      <BottomTab.Screen
        name="createlisting"
        component={CreateListingNavigator}
        options={{
          tabBarIcon: ({ color }) => <TabBarIcon name="ios-code" color={color} />,
          tabBarVisible: Platform.OS === "web" ? false : true,
        }}
      />
      <BottomTab.Screen
        name="createflatmate"
        component={CreateFlatmateNavigator}
        options={{
          tabBarIcon: ({ color }) => <TabBarIcon name="ios-code" color={color} />,
          tabBarVisible: Platform.OS === "web" ? false : true,
        }}
      />
      <BottomTab.Screen
        name="information"
        component={InformationNavigator}
        options={{
          tabBarIcon: ({ color }) => <TabBarIcon name="ios-code" color={color} />,
          tabBarVisible: Platform.OS === "web" ? false : true,
        }}
      />
      <BottomTab.Screen
        name="editlisting"
        component={EditListingNavigator}
        options={{
          tabBarIcon: ({ color }) => <TabBarIcon name="ios-code" color={color} />,
          tabBarVisible: Platform.OS === "web" ? false : true,
        }}
      />
      <BottomTab.Screen
        name="editflatmatelisting"
        component={EditFlatmateListingNavigator}
        options={{
          tabBarIcon: ({ color }) => <TabBarIcon name="ios-code" color={color} />,
          tabBarVisible: Platform.OS === "web" ? false : true,
        }}
      />
      <BottomTab.Screen
        name="viewlisting"
        component={ViewListingNavigator}
        options={{
          tabBarIcon: ({ color }) => <TabBarIcon name="ios-code" color={color} />,
          tabBarVisible: Platform.OS === "web" ? false : true,
        }}
      />
      <BottomTab.Screen
        name="messages"
        component={MessagesNavigator}
        options={{
          tabBarIcon: ({ color }) => <TabBarIcon name="ios-code" color={color} />,
          tabBarVisible: Platform.OS === "web" ? false : true,
        }}
      />
      <BottomTab.Screen
        name="favourites"
        component={FavouritesNavigator}
        options={{
          tabBarIcon: ({ color }) => <TabBarIcon name="ios-code" color={color} />,
          tabBarVisible: Platform.OS === "web" ? false : true,
        }}
      />
    </BottomTab.Navigator>
  );
}

// You can explore the built-in icon families and icons on the web at:
// https://icons.expo.fyi/
function TabBarIcon(props: { name: string; color: string }) {
  return <Ionicons size={30} style={{ marginBottom: -3 }} {...props} />;
}

// Each tab has its own navigation stack, you can read more about this pattern here:
// https://reactnavigation.org/docs/tab-based-navigation#a-stack-navigator-for-each-tab
const LoadingStack = createStackNavigator<LoadingParamList>();

function LoadingNavigator(navProp: any) {
  return (
    <LoadingStack.Navigator>
      <LoadingStack.Screen
        name="LoadingScreen"
        component={() => <LoadingScreen navProp={navProp} />}
        options={{ 
          headerTitle: 'flatting.co.nz',
          header: () => Platform.OS === 'web' ? <WebsiteHeader navProp={navProp} /> : null,
        }}
      />
    </LoadingStack.Navigator>
  );
}

const LoginStack = createStackNavigator<LoginParamList>();

function LoginNavigator(navProp: any) {
  const login = () => <LoginScreen navProp={navProp} />

  return (
    <LoginStack.Navigator>
      <LoginStack.Screen
        name="LoginScreen"
        component={login}
        options={{ 
          headerTitle: 'flatting.co.nz',
          header: () => Platform.OS === 'web' ? <WebsiteHeader navProp={navProp} /> : null,
        }}
      />
    </LoginStack.Navigator>
  );
}

const HomeStack = createStackNavigator<HomeParamList>();

function HomeNavigator(navProp: any) {
  const home = () => <HomeScreen navProp={navProp} />

  return (
    <HomeStack.Navigator>
      <HomeStack.Screen
        name="Home"
        component={home}
        options={{ 
          headerTitle: 'flatting.co.nz',
          header: () => null,
        }}
      />
    </HomeStack.Navigator>
  );
}

const ProfileStack = createStackNavigator<ProfileParamList>();

function ProfileNavigator(navProp: any) {
  const profile = () => <ProfileScreen navProp={navProp} />

  return (
    <ProfileStack.Navigator>
      <ProfileStack.Screen
        name="Profile"
        component={profile}
        options={() => ({ 
          headerTitle: 'flatting.co.nz',
          header: () => null,
        })}
      />
    </ProfileStack.Navigator>
  );
}

const ListingsStack = createStackNavigator<ListingsParamList>();

function ListingsNavigator(navProp: any) {
  const listing = () => <ListingsScreen navProp={navProp}/>

  return (
    <ListingsStack.Navigator>
      <ListingsStack.Screen
        name="ListingsScreen"
        component={listing}
        options={{ 
          headerTitle: 'flatting.co.nz',
          header: () => null,
        }}
      />
    </ListingsStack.Navigator>
  );
}

const CreateListingStack = createStackNavigator<CreateListingParamList>();

function CreateListingNavigator(navProp: any) {
  const createlisting = () => <CreateListingScreen navProp={navProp}/>
  return (
    <CreateListingStack.Navigator>
      <CreateListingStack.Screen
        name="CreateListingScreen"
        component={createlisting}
        options={{ 
          headerTitle: 'flatting.co.nz',
          header: () => null,
        }}
      />
    </CreateListingStack.Navigator>
  );
}

const CreateFlatmateStack = createStackNavigator<CreateFlatmateParamList>();

function CreateFlatmateNavigator(navProp: any) {
  const createflatmate = () => <CreateFlatmateScreen navProp={navProp}/>
  return (
    <CreateFlatmateStack.Navigator>
      <CreateFlatmateStack.Screen
        name="CreateFlatmateScreen"
        component={createflatmate}
        options={{ 
          headerTitle: 'flatting.co.nz',
          header: () => null,
        }}
      />
    </CreateFlatmateStack.Navigator>
  );
}

const InformationalStack = createStackNavigator<InformationalParamList>();

function InformationNavigator(navProp: any) {
  const informational = () => <InformationalScreen navProp={navProp}/>
  return (
    <InformationalStack.Navigator>
      <InformationalStack.Screen
        name="InformationalScreen"
        component={informational}
        options={{ 
          headerTitle: 'flatting.co.nz',
          header: () => null,
        }}
      />
    </InformationalStack.Navigator>
  );
}

const EditListingStack = createStackNavigator<EditListingParamList>();

function EditListingNavigator(navProp: any) {
  const editlisting = () => <EditListingScreen navProp={navProp}/>

  return (
    <EditListingStack.Navigator>
      <EditListingStack.Screen
        name="EditListingScreen"
        component={editlisting}
        options={{ 
          headerTitle: 'flatting.co.nz',
          header: () => null,
        }}
      />
    </EditListingStack.Navigator>
  );
}

const EditFlatmateListingStack = createStackNavigator<EditFlatmateListingParamList>();

function EditFlatmateListingNavigator(navProp: any) {
  const editflatmatelisting = () => <EditFlatmateListingScreen navProp={navProp}/>

  return (
    <EditFlatmateListingStack.Navigator>
      <EditFlatmateListingStack.Screen
        name="EditFlatmateListingScreen"
        component={editflatmatelisting}
        options={{ 
          headerTitle: 'flatting.co.nz',
          header: () => null,
        }}
      />
    </EditFlatmateListingStack.Navigator>
  );
}

const ViewListingStack = createStackNavigator<ViewListingParamList>();

function ViewListingNavigator(navProp: any) {
  const viewlisting = () => <ViewListingScreen navProp={navProp} />
  return (
    <ViewListingStack.Navigator>
      <ViewListingStack.Screen
        name="ViewListingScreen"
        component={viewlisting}
        options={{ 
          headerTitle: 'flatting.co.nz',
          header: () => null,
        }}
      />
    </ViewListingStack.Navigator>
  );
}

const ViewFlatmateListingStack = createStackNavigator<ViewFlatemateListingParamList>();

function ViewFlatmateListingsNavigator(navProp: any) {
  const viewflatmatelisting = () => <ViewFlatmateListingScreen navProp={navProp} />
  return (
    <ViewFlatmateListingStack.Navigator>
      <ViewFlatmateListingStack.Screen
        name="ViewFlatmateListingScreen"
        component={viewflatmatelisting}
        options={{ 
          headerTitle: 'flatting.co.nz',
          header: () => null,
        }}
      />
    </ViewFlatmateListingStack.Navigator>
  );
}

const MessagesStack = createStackNavigator<MessagesParamList>();

function MessagesNavigator(navProp: any) {
  const messages = () => <MessagesScreen navProp={navProp} />
  
  return (
    <MessagesStack.Navigator>
      <MessagesStack.Screen
        name="Messages"
        component={messages}
        options={{ 
          headerTitle: 'flatting.co.nz',
          header: () => null,
        }}
      />
    </MessagesStack.Navigator>
  );
}

const FavouritesStack = createStackNavigator<FavouritesParamList>();

function FavouritesNavigator(navProp: any) {
  const favorites = () => <FavouritesScreen navProp={navProp} />

  return (
    <FavouritesStack.Navigator>
      <FavouritesStack.Screen
        name="Favourites"
        component={favorites}
        options={{ 
          headerTitle: 'flatting.co.nz',
          header: () => null,
        }}
      />
    </FavouritesStack.Navigator>
  );
}
