import _ from 'lodash';
import React, { useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
 
const GooglePlacesInputWeb = ({ updateLocation } : { updateLocation : any }) => {
    return (
        <div>
        <GooglePlacesAutocomplete
          apiKey='AIzaSyDUVrdF1z_vjhsQVF-fMUF03jDP0FW_KdM'
          autocompletionRequest={{
            componentRestrictions: {
            country: ['nz'],
            },
          }}
          onLoadFailed={(error) => console.log(error)}
          selectProps={{
            onChange: updateLocation,
            placeholder: 'Search for location...',
            styles: {
                input: (provided) => ({
                    ...provided,
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingLeft: 10,
                    fontFamily: 'arial',
                    fontSize: 16,

                }),
                valueContainer: (provided, state) => ({
                    ...provided,
                    border: '0',
                }),
                control: (provided, state) => ({
                    ...provided,
                    border: state.isFocused && '2px solid #fed800 !important',
                    boxShadow: '0px solid white !important',
                    borderRadius: 30
                }),
                placeholder: (provided) => ({
                    ...provided,
                    fontFamily: 'arial',
                    fontSize: 16,
                    paddingLeft: 10,
                }),
                singleValue: (provided) => ({
                    ...provided,
                    fontFamily: 'arial',
                    fontSize: 16,
                    paddingLeft: 10
                }),
                menuList: (provided) => ({
                    ...provided,
                    fontFamily: 'arial',
                    fontSize: 16,
                    margin: 5,
                    padding: 0
                }),
                menu: (provided) => ({
                    ...provided,
                }),
                menuPortal: (provided) => ({
                    ...provided,
                }),
                dropdownIndicator: (provided) => ({
                    ...provided,
                    marginRight: 5,
                    color: '#000',
                    borderWidth: 0
                }),
                indicatorSeparator: (provided) => ({
                    ...provided,
                    width: 0
                }),
            }
          }}
        />
      </div>
    )
}
 
export default GooglePlacesInputWeb;