// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseWebConfig = {
    apiKey: "AIzaSyAzblx9VJvdJbS59qUJo3eXgCSx4kq3Fog",
    authDomain: "flatting-live.firebaseapp.com",
    databaseURL: "https://flatting-live-default-rtdb.firebaseio.com",
    projectId: "flatting-live",
    storageBucket: "flatting-live.appspot.com",
    messagingSenderId: "501200069532",
    appId: "1:501200069532:web:a3242fdf272e0d176fde59",
    measurementId: "G-H0MF0EX9PZ"
};

export const firebaseWebMessageConfig = {
    apiKey: "AIzaSyAzblx9VJvdJbS59qUJo3eXgCSx4kq3Fog",
    authDomain: "flatting-live.firebaseapp.com",
    databaseURL: "https://flatting-live-messages.firebaseio.com",
    projectId: "flatting-live",
    storageBucket: "flatting-live.appspot.com",
    messagingSenderId: "501200069532",
    appId: "1:501200069532:web:a3242fdf272e0d176fde59",
    measurementId: "G-H0MF0EX9PZ"
};