import _ from 'lodash';;
import moment from 'moment'
import React, { useEffect, useState, useContext } from 'react';

import * as Linking from 'expo-linking';

import { storage, database } from 'firebase';

import { UserContext } from '../context/UserContext';

import { ButtonGroup } from 'react-native-elements';
import { View, Text, StyleSheet, Image, Dimensions, ScrollView, TouchableOpacity, Platform } from 'react-native';
import { Textarea, Button, Application, DatePicker, Input, ProgressIndicator, ProgressStep, RadioButtonGroup, CounterInput, Picklist, Option, Spinner } from 'react-rainbow-components';

import EditImageUploaderWeb from '../components/EditImageUploaderWeb';
import GooglePlacesInputWeb from '../components/GooglePlacesInputWeb';
import ConfirmScreen from '../components/ConfirmScreen';
import WebsiteHeader from '../components/WebsiteHeader';

const deviceWidth = Dimensions.get("window").width;
const deviceHeight = Dimensions.get("window").height;

const PropertyTypeValues = ['Apartment', 'House', 'Lifestyle Block', 'Townhouse', 'Unit'];
const BedroomsValues = ['1', '2', '3', '4', '5', '6+'];
const BathroomsValues = ['1', '2', '3', '4', '5', '6+'];

const petOptions = [
  { value: "Don't Know", label: "Don't Know" },
  { value: 'No', label: 'No' },
  { value: 'Yes', label: 'Yes' },
  { value: 'Negotiable', label: 'Negotiable' },
];

const couplesOption = [
  { value: "Don't Know", label: "Don't Know" },
  { value: 'No', label: 'No' },
  { value: 'Yes', label: 'Yes' },
  { value: 'Negotiable', label: 'Negotiable' },
];

const smokersOption = [
  { value: "Don't Know", label: "Don't Know" },
  { value: 'No', label: 'No' },
  { value: 'Yes', label: 'Yes' },
  { value: 'Negotiable', label: 'Negotiable' },
];

const stepNames = ['details', 'photos', 'confirm'];

export default function EditListingScreen({ navProp }) {
  const [userInfo, setUserInfo] = useContext(UserContext);
  const [listing, setListing] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const [detailsValid, setDetailsValid] = useState(false);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const [rent, setRent] = useState(0);
  const [bond, setBond] = useState({ name: 'None', label: 'None' });
  const [bills, setBills] = useState({ name: 'Additional to the rent', label: 'Additional to the rent' });
  const [date, setDate] = useState(new Date());

  const [propertyTypeIndex, setPropertyTypeIndex] = useState();
  const [propertyType, setPropertyType] = useState('');

  const [bedrooms, setBedrooms] = useState<number>();
  const [bedroomsValue, setBedroomsValue] = useState('');

  const [bathrooms, setBathrooms] = useState<number>();
  const [bathroomsValue, setBathroomsValue] = useState('');

  const [flatmates, setFlatmates] = useState(0);

  const [location, setLocation] = useState({ label: '', value: { terms: { length: 0 } } });

  const [parking, setParking] = useState({ name: 'No parking', label: 'No parking' });
  const [internet, setInternet] = useState({ name: 'No internet', label: 'No internet' });
  const [details, setDetails] = useState('');
  const [pets, setPets] = useState("Don't Know");
  const [couples, setCouples] = useState("Don't Know");
  const [smokers, setSmokers] = useState("Don't Know");

  const [currentFlatmates, setCurrentFlatmates] = useState('No Details');
  const [idealFlatmate, setIdealFlatmate] = useState('No Details');
  const [amenities, setAmenities] = useState('No Details');
  const [furnishing, setFurnishing] = useState('No Details');

  const [userPhotos, setUserPhotos] = useState();

  const shadow = { 
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.06,
    shadowRadius: 20,
    elevation: 14,
  }

  useEffect(() => {
    if(userInfo.uid) {
      setLoading(false);
    }

    setTimeout(() => {
      setLoading(false);
    }, 7000);
  })

  useEffect(() => {
    fetchListing();
  }, [!_.isEmpty(listing)]);

  useEffect(() => {
    if (rent && date && propertyType && bathroomsValue && bedroomsValue && location.label && details) {
      setDetailsValid(true);
    } else {
      setDetailsValid(false);
    }
  })

  const fetchListing = async() => {
      var mainDB = database();

      Linking
      .getInitialURL()
      .then((url: any) => {
          var listingId = Linking.parse(url).queryParams?.id;

          if(listingId) {
              mainDB
                  .ref(`listings/New Zealand/${listingId}`)
                  .once('value', snapshot => {
                      let listing = snapshot.val();
                      let date = moment.unix(listing.date).toDate();
                      let propertyIndex: any = _.indexOf(PropertyTypeValues, listing?.propertyType);
                      let bedrooms = parseInt(listing.bedrooms);
                      let bathrooms = parseInt(listing.bathrooms);

                      setListing(listing);
                      setRent(listing.rent);
                      setBond({ name: listing.bond, label: listing.bond });
                      setBills({ name: listing.bills, label: listing.bills });
                      setDate(date);
                      setPropertyType(listing.propertyType);
                      setPropertyTypeIndex(propertyIndex);
                      setBedroomsValue(listing.bedrooms);
                      setBedrooms(bedrooms);
                      setBathrooms(bathrooms);
                      setBathroomsValue(listing.bathrooms);
                      setFlatmates(listing.flatmates);
                      setLocation(listing.location);
                      setParking({ name: listing.parking, label: listing.parking });
                      setInternet({ name: listing.internet, label: listing.internet });
                      setPets(listing.pets);
                      setCouples(listing.couples);
                      setSmokers(listing.smokers);
                      setDetails(listing.details);
                      setCurrentFlatmates(listing.currentFlatmates);
                      setIdealFlatmate(listing.idealFlatmates);
                      setAmenities(listing.amenities);
                      setFurnishing(listing.furnishing);
                      setUserPhotos(listing.photos);
                      setLoading(false);
                  })
          }
      });
  }

  const updateListing = async() => {
    let userID = userInfo.uid;
    let country = location?.value?.terms[location.value.terms.length - 1].value;
    let city = location?.value?.terms[location.value.terms.length - 2].value;

    
    const listingData = {
        id: listing.id,
        city: city,
        created: listing.created,
        updated: moment().unix(),
        user: userInfo,
        rent: rent,
        bond: bond.label,
        bills: bills.label,
        date: moment(date).unix(),
        propertyType: propertyType,
        bedrooms: bedroomsValue,
        bathrooms: bathroomsValue,
        flatmates: flatmates,
        location: location,
        parking: parking.label,
        internet: internet.label,
        details: details,
        pets: pets,
        couples: couples,
        smokers: smokers,
        views: listing.views,
        currentFlatmates: currentFlatmates,
        idealFlatmates: idealFlatmate,
        amenities: amenities,
        furnishing: furnishing,
        photos: userPhotos,
        status: 'open',
        type: 'flat'
    }

    const dataToUpdate = {
        [`listings/${country}/${listing.id}`] : listingData,
    }

    await database().ref().update(dataToUpdate);
    navProp.navigation.navigate('profile');
  }

  const updatePropertyType = (value: any) => {
    setPropertyTypeIndex(value);
    setPropertyType(PropertyTypeValues[value]);
  }

  const updateBedrooms = (value: any) => { setBedrooms(value); setBedroomsValue(BedroomsValues[value]); }
  const updateBathrooms = (value: any) => { setBathrooms(value); setBathroomsValue(BathroomsValues[value])}
  const updateUserPhotos = (photos: any) => { setUserPhotos(photos) }

  // console.log(listing);

  return (
    <View style={{flex: 1 }}>
    <ScrollView 
      style={styles.container}
      contentContainerStyle={{ width: '100%', height: '100%' }}
      showsHorizontalScrollIndicator={false}>
      <WebsiteHeader navProp={navProp} />
      {(loading || !userInfo.uid) && <View style={[shadow, { height: '95%', width: '100%', borderRadius: 10, marginTop: 20, maxWidth: 1200, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white' }]}>
        <Application theme={{ rainbow: { palette: { brand: '#fed800' } } }} style={{ width: '100%' }}><Spinner variant="brand" size="large" /></Application>
      </View>}
      {(!userInfo.uid && !loading) && <View style={[shadow, { height: '95%', width: '100%', borderRadius: 10, marginTop: 20, maxWidth: 1200, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white' }]}>
        <Image source={require('../assets/images/login.svg')} style={{ width: deviceWidth < 600 ? '85%' : '40%', height: deviceWidth < 600 ? 200 : 300, resizeMode: 'contain' }} />
        <Text style={{ paddingTop: 40, fontSize: 22, fontWeight: 'bold', textAlign: 'center' }}>Login Required</Text>
        <Text style={{ paddingTop: 10, fontSize: 16, color: 'gray', textAlign: 'center' }}>Looks like you're not logged In.</Text>
        <Text style={{ paddingTop: 10, fontSize: 16, color: 'gray', textAlign: 'center' }}>Please login to continue.</Text>
      </View>}
    {userInfo.firstName ? <Application theme={{ rainbow: { palette: { brand: '#3f4954' } } }}>
        <View style={{ width: '100%', height: 65, alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
            <ProgressIndicator currentStepName={stepNames[currentStepIndex]} onClick={(name: any) => setCurrentStepIndex(name)}>
                <ProgressStep name="details" label='Details' />
                <ProgressStep name="photos" label='Photos' />
                <ProgressStep name="confirm" label='Confirm' />
            </ProgressIndicator>
        </View>
      </Application> : null}
      {(currentStepIndex === 0 && userInfo.uid) ? <View style={{ width: '100%', maxWidth: 1200, alignSelf: "center", justifyContent: 'space-between', alignItems: 'center', marginTop: 30 }}>
        <Image source={{ uri: userInfo.photoUrl }} style={{ height: 60, width: 60, borderRadius: 30, marginBottom: 10 }} />
        <Text style={{ fontSize: 22, paddingVertical: 10, paddingHorizontal: 25, lineHeight: 32, fontFamily: 'pacifico-regular', textAlign: 'center' }}>Hi {userInfo.firstName}, Welcome back to your listing</Text>
        <Text style={{ fontSize: 16, paddingBottom: 10, paddingTop: 10, color: 'gray' }}><Text style={{ color: 'red' }}>* </Text>Indicates a required field.</Text>
        <View style={{ width: '100%', padding: 30, marginTop: 20, borderRadius: 10, backgroundColor: 'white', marginBottom: deviceWidth < 500 ? 10 : 20 }}>
          <Text style={{ fontSize: 22, fontWeight: 'bold', paddingBottom: 20, color: '#464646' }}>Listing Details</Text>
          <Text style={{ fontSize: 16, paddingBottom: 10 }}>Rent per week<Text style={{ color: 'red' }}> *</Text></Text>
          <Application theme={{ rainbow: { palette: { brand: '#fed800' } } }}>
            <Input
              style={{ marginBottom: 30, maxWidth: 150 }}
              placeholder='0.00'
              value={rent}
              maxLength={6}
              onChange={(e : any) => setRent(e.target.value)}
              type='number'
              icon={<Image source={{ uri: "https://img.icons8.com/material-rounded/96/000000/us-dollar.png" }} style={{ height: 15, width: 15, opacity: 0.6 }} />}
             />
          </Application>
          <Text style={{ fontSize: 16, paddingBottom: 10 }}>Bond<Text style={{ color: 'red' }}> *</Text></Text>
          <Application theme={{ rainbow: { palette: { brand: '#fed800' } } }}>
          <Picklist
              id='bond'
              style={{ marginBottom: 30, maxWidth: 320 }}
              onChange={(value: any) => setBond(value)}
              hideLabel
              value={bond}>
                <Option name="header" label="Bond Options" variant="header" />
                <Option name="None" label="None" />
                <Option name="1 Week" label="1 Week" />
                <Option name="2 Weeks" label="2 Weeks" />
                <Option name="3 Weeks" label="3 Weeks" />
                <Option name="4 Weeks" label="4 Weeks" />
            </Picklist>
          </Application>
          <Text style={{ fontSize: 16, paddingBottom: 10 }}>Bills<Text style={{ color: 'red' }}> *</Text></Text>
          <Application theme={{ rainbow: { palette: { brand: '#fed800' } } }}>
          <Picklist
              id='bills'
              style={{ marginBottom: 30, maxWidth: 320 }}
              onChange={(value: any) => setBills(value)}
              hideLabel
              value={bills}>
                <Option name="header" label="Bills Options" variant="header" />
                <Option name="Additional to the rent" label="Additional to the rent" />
                <Option name="Some included in the rent" label="Some included in the rent" />
                <Option name="Included in the rent" label="Included in the rent" />
            </Picklist>
          </Application>
          <Text style={{ fontSize: 16, paddingBottom: 10 }}>Available From<Text style={{ color: 'red' }}> *</Text></Text>
          <Application theme={{ rainbow: { palette: { brand: '#fed800' } } }}>
          <DatePicker
                id="datePicker-1"
                value={date}
                style={{ fontFamily: 'arial', width: '100%', maxWidth: 400, marginBottom: 5 }}
                onChange={setDate}
                minDate={new Date()}
                formatStyle="medium"
                locale='en'
            />
            </Application>
        </View>
        <View style={{ width: '100%', padding: 30, marginTop: 20, borderRadius: 10, backgroundColor: 'white', marginBottom: deviceWidth < 500 ? 10 : 20 }}>
            <Text style={{ fontSize: 22, fontWeight: 'bold', paddingBottom: 20, color: '#464646' }}>Property Details</Text>
            <Text style={{ fontSize: 16, paddingBottom: 10 }}>Property Type<Text style={{ color: 'red' }}> *</Text></Text>
            <View style={{ flexDirection: deviceWidth < 1000 ? 'column' : 'row', paddingBottom: 20 }}>
              <ButtonGroup
                onPress={updatePropertyType}
                selectedIndex={propertyTypeIndex}
                buttons={PropertyTypeValues}
                vertical={deviceWidth < 500 ? true : false}
                containerStyle={{ minHeight: 60, width: '100%', marginLeft: 0, marginRight: 0, borderRadius: 50 }}
                buttonContainerStyle= {{ marginLeft: 0, marginRight: 0 }}
                selectedButtonStyle={{ backgroundColor: '#fed800' }}
                textStyle={{ textAlign: 'center', fontSize: 16 }}
              />
            </View>
            <Text style={{ fontSize: 16, paddingBottom: 5 }}>Bedrooms<Text style={{ color: 'red' }}> *</Text></Text>
            <Text style={{ fontSize: 14, paddingBottom: 10, color: 'gray' }}>Including the one that you're offering</Text>
            <View style={{ flexDirection: deviceWidth < 1000 ? 'column' : 'row', paddingBottom: 20 }}>
              <ButtonGroup
                onPress={updateBedrooms}
                selectedIndex={bedrooms}
                buttons={BedroomsValues}
                textStyle={{ fontSize: 16 }}
                containerStyle={{height: 50, width: '100%', marginLeft: 0, marginRight: 0, borderRadius: 50 }}
                buttonContainerStyle= {{ marginLeft: 0, marginRight: 0 }}
                selectedButtonStyle={{ backgroundColor: '#fed800' }}
              />
            </View>
            <Text style={{ fontSize: 16, paddingBottom: 10 }}>Bathrooms<Text style={{ color: 'red' }}> *</Text></Text>
            <View style={{ flexDirection: deviceWidth < 1000 ? 'column' : 'row', paddingBottom: 20 }}>
              <ButtonGroup
                onPress={updateBathrooms}
                selectedIndex={bathrooms}
                buttons={BathroomsValues}
                textStyle={{ fontSize: 16 }}
                containerStyle={{ height: 50, width: '100%', marginLeft: 0, marginRight: 0, borderRadius: 50 }}
                buttonContainerStyle= {{ marginLeft: 0, marginRight: 0 }}
                selectedButtonStyle={{ backgroundColor: '#fed800' }}
              />
            </View>
            <Text style={{ fontSize: 16, paddingBottom: 15 }}>Existing Flatmates<Text style={{ color: 'red' }}> *</Text></Text>
            <View style={{ flexDirection: 'row' }}>
              <Application theme={{ rainbow: { palette: { brand: '#fed800' } } }}>
                <CounterInput
                      id="flatmates-input-component"
                      labelAlignment="left"
                      value={flatmates}
                      min={0}
                      max={12}
                      onChange={setFlatmates}
                  />
              </Application>
            </View>
        </View>
        <View style={{ width: '100%', padding: 30, marginTop: 20, borderRadius: 10, backgroundColor: 'white', marginBottom: deviceWidth < 500 ? 10 : 20 }}>
          <Text style={{ fontSize: 22, fontWeight: 'bold', paddingBottom: 20, color: '#464646' }}>Location<Text style={{ color: 'red' }}> *</Text></Text>
          {location.label ? <Text style={{ fontSize: deviceWidth < 500 ? 16 : 20, paddingBottom: 25 }}>{location.label}</Text> : null}
          <GooglePlacesInputWeb updateLocation={setLocation} />
        </View>
        <View style={{ width: '100%', padding: 30, marginTop: 20, borderRadius: 10, backgroundColor: 'white', marginBottom: deviceWidth < 500 ? 10 : 20, position: 'relative', zIndex: -1 }}>
          <Text style={{ fontSize: 22, fontWeight: 'bold', paddingBottom: 20, color: '#464646' }}>About the property</Text>
          <Text style={{ fontSize: 16, paddingBottom: 15 }}>Parking<Text style={{ color: 'red' }}> *</Text></Text>
          <View style={{ flexDirection: 'row', marginBottom: 20 }}>
          <Application theme={{ rainbow: { palette: { brand: '#fed800' } } }}>
            <Picklist
              id='parking'
              onChange={(value: any) => setParking(value)}
              hideLabel
              value={parking}>
                <Option name="header" label="Parking Options" variant="header" />
                <Option name="No parking" label="No Parking" />
                <Option name="Off-street parking" label="Off-strett parking" />
                <Option name="On-street parking" label="On-street parking" />
            </Picklist>
          </Application>
          </View>
          <Text style={{ fontSize: 16, paddingBottom: 15 }}>Internet<Text style={{ color: 'red' }}> *</Text></Text>
          <View style={{ flexDirection: 'row', marginBottom: 20 }}>
          <Application theme={{ rainbow: { palette: { brand: '#fed800' } } }}>
            <Picklist
              id='internet'
              onChange={(value: any) => setInternet(value)}
              hideLabel
              value={internet}>
                <Option name="header" label="Internet Options" variant="header" />
                <Option name="Available but not included in rent" label="Available but not included in rent" />
                <Option name="Included in rent" label="Included in rent" />
                <Option name="Unlimited included in rent" label="Unlimited included in rent" />
            </Picklist>
          </Application>
          </View>
          <Text style={{ fontSize: 16, paddingBottom: 15 }}>Pets OK</Text>
          <RadioButtonGroup
                id="radio-button-group-component-pets"
                options={petOptions}
                size={deviceWidth < 500 ? 'small' : 'medium'}
                value={pets}
                style={{ alignSelf: 'flex-start', whiteSpace: 'nowrap', marginBottom: 30, tabSize: 10 }}
                onChange={(e : any) => setPets(e.target.value)}
          />
          <Text style={{ fontSize: 16, paddingBottom: 15 }}>Couples OK</Text>
          <RadioButtonGroup
                id="radio-button-group-component-couples"
                size={deviceWidth < 500 ? 'small' : 'medium'}
                options={couplesOption}
                value={couples}
                style={{ alignSelf: 'flex-start', whiteSpace: 'nowrap', marginBottom: 30 }}
                onChange={(e : any) => setCouples(e.target.value)}
            />
          <Text style={{ fontSize: 16, paddingBottom: 15 }}>Smokers OK</Text>
          <RadioButtonGroup
                id="radio-button-group-component-smokers"
                options={smokersOption}
                value={smokers}
                size={deviceWidth < 500 ? 'small' : 'medium'}
                style={{ alignSelf: 'flex-start', whiteSpace: 'nowrap', marginBottom: 30 }}
                onChange={(e : any) => setSmokers(e.target.value)}
            />
          <Text style={{ fontSize: 16, paddingBottom: 15 }}>Other Details<Text style={{ color: 'red' }}> *</Text></Text>
          <Application theme={{ rainbow: { palette: { brand: '#fed800' } } }}>
            <Textarea 
              onChange={event => setDetails(event.target.value)}
              value={details}
              placeholder='e.g. sunny, garden, decks, air conditioned...'
              maxLength={3000}            
              rows={8}
              style={{ borderRadius: 10, borderWidth: 0.5, borderColor: '#e3e3e3', width: deviceWidth < 650 ? '100%' : '60%', marginBottom: 5, fontSize: 18, lineHeight: 25 }}
            />
          </Application>
        </View>
        <View style={{ width: '100%', padding: 30, marginTop: 20, borderRadius: 10, backgroundColor: 'white', marginBottom: deviceWidth < 500 ? 10 : 20 }}>
          <Text style={{ fontSize: 22, fontWeight: 'bold', paddingBottom: 20, color: '#464646' }}>About the current flatmates</Text>
          <Application theme={{ rainbow: { palette: { brand: '#fed800' } } }}>
            <Textarea 
              value={currentFlatmates}
              onChange={event => setCurrentFlatmates(event.target.value)}
              placeholder='Describe your interests, lifestyle and hobbies...'
              maxLength={3000}            
              rows={5}
              style={{ borderRadius: 10, borderWidth: 0.5, borderColor: '#e3e3e3', width: deviceWidth < 650 ? '100%' : '60%', marginBottom: 5, fontSize: 18, lineHeight: 25 }}
            />
          </Application>
        </View>
        <View style={{ width: '100%', padding: 30, marginTop: 20, borderRadius: 10, backgroundColor: 'white', marginBottom: deviceWidth < 500 ? 10 : 20 }}>
          <Text style={{ fontSize: 22, fontWeight: 'bold', paddingBottom: 20, color: '#464646' }}>Your ideal flatmate?</Text>
          <Application theme={{ rainbow: { palette: { brand: '#fed800' } } }}>
            <Textarea 
              value={idealFlatmate}
              onChange={event => setIdealFlatmate(event.target.value)}
              placeholder='e.g. interests, lifestyle and hobbies...'
              maxLength={3000}
              rows={5}
              style={{ borderRadius: 10, borderWidth: 0.5, borderColor: '#e3e3e3', width: deviceWidth < 650 ? '100%' : '60%', marginBottom: 5, fontSize: 18, lineHeight: 25 }}
            />
          </Application>
        </View>
        <View style={{ width: '100%', padding: 30, marginTop: 20, borderRadius: 10, backgroundColor: 'white', marginBottom: deviceWidth < 500 ? 10 : 20 }}>
          <Text style={{ fontSize: 22, fontWeight: 'bold', paddingBottom: 20, color: '#464646' }}>Amenities in the area</Text>
          <Application theme={{ rainbow: { palette: { brand: '#fed800' } } }}>
            <Textarea 
              value={amenities}
              onChange={event => setAmenities(event.target.value)}
              placeholder='e.g. schools, parks, shops...'
              maxLength={3000}            
              rows={5}
              style={{ borderRadius: 10, borderWidth: 0.5, borderColor: '#e3e3e3', width: deviceWidth < 650 ? '100%' : '60%', marginBottom: 5, fontSize: 18, lineHeight: 25 }}
            />
          </Application>
        </View>
        <View style={{ width: '100%', padding: 30, marginTop: 20, borderRadius: 10, backgroundColor: 'white', marginBottom: deviceWidth < 500 ? 10 : 20 }}>
          <Text style={{ fontSize: 22, fontWeight: 'bold', paddingBottom: 20, color: '#464646' }}>Furnishings & whiteware provided</Text>
          <Application theme={{ rainbow: { palette: { brand: '#fed800' } } }}>
            <Textarea 
              value={furnishing}
              onChange={event => setFurnishing(event.target.value)}
              placeholder='e.g. table, bed, wardrobe...'
              maxLength={3000}            
              rows={5}
              style={{ borderRadius: 10, borderWidth: 0.5, borderColor: '#e3e3e3', width: deviceWidth < 650 ? '100%' : '60%', marginBottom: 5, fontSize: 18, lineHeight: 25 }}
            />
          </Application>
        </View>
        <View style={{ width: '100%', borderRadius: 10, marginBottom: 30, backgroundColor: 'white', padding: 20 }}>
          <Application theme={{ rainbow: { palette: { brand: '#fed800' } } }} style={{ width: deviceWidth < 550 ? '100%' : '30%', alignSelf: 'flex-end' }}>
            <Button
                label="Next"
                onClick={() => setCurrentStepIndex(1)}
                variant="brand"
                style={{ borderRadius: 5, color: !detailsValid ? 'gray' : 'black', width: '100%', }}
                disabled={!detailsValid}
            />
          </Application>
        </View>
      </View> : null}
      {(currentStepIndex === 1 && userInfo.uid) ?
      <View style={{ flex: 1, width: '100%', maxWidth: 1200, alignSelf: 'center' }}>
        <Text style={{ fontSize: 22, paddingBottom: 5, paddingTop: 30, paddingLeft: 15, fontWeight: 'bold' }}>Photos</Text>
        <Text style={{ fontSize: 16, color: 'gray', paddingBottom: 20, paddingLeft: 15, }}>Upload up to 15 photos for your listing, making sure that they are good quality and small in size. Images that take too long to load up can put some users off.</Text>
        <EditImageUploaderWeb userInfo={userInfo} updateUserPhotos={updateUserPhotos} photos={userPhotos} listingId={listing.id} />
        <View style={{ width: '100%', borderRadius: 10, marginTop: 20, marginBottom: 30, backgroundColor: 'white', padding: 20, flexDirection: 'row', justifyContent: 'space-between' }}>
        <Application theme={{ rainbow: { palette: { brand: '#000' } } }} style={{ width: deviceWidth < 550 ? '100%' : '30%', alignSelf: 'flex-start', marginRight: deviceWidth < 505 ? 5 : 0 }}>
              <Button
                  label="Previous"
                  onClick={() => setCurrentStepIndex(0)}
                  variant="outline-brand"
                  style={{ borderRadius: 5, width: '100%' }}
              />
          </Application>
          <Application theme={{ rainbow: { palette: { brand: '#fed800' } } }} style={{ width: deviceWidth < 550 ? '100%' : '30%', alignSelf: 'flex-end' }}>
              <Button
                  label="Next"
                  onClick={() => setCurrentStepIndex(2)}
                  variant="brand"
                  style={{ borderRadius: 5, color: '#000', width: '100%', }}
              />
          </Application>
        </View>
      </View> : null}
      {(currentStepIndex === 2 && userInfo.uid) ?
      <View style={{ width: '100%', maxWidth: 1200, alignSelf: 'center' }}>
        <Text style={{ fontSize: 22, paddingBottom: 5, paddingTop: 30, paddingLeft: 15, fontWeight: 'bold' }}>Confirm</Text>
        <Text style={{ fontSize: 16, color: 'gray', paddingBottom: 20, paddingLeft: 15, }}>Please make sure that you're happy with your listing details</Text>
        <ConfirmScreen 
            userInfo={userInfo}
            rent={rent}
            bond={bond.label}
            bills={bills.label}
            date={date}
            propertyType={propertyType}
            bedroomsValue={bedroomsValue}
            bathroomsValue={bathroomsValue}
            flatmates={flatmates}
            location={location}
            parking={parking}
            internet={internet}
            details={details}
            pets={pets}
            couples={couples}
            smokers={smokers}
            currentFlatmates={currentFlatmates} 
            idealFlatmates={idealFlatmate}
            amenities={amenities}
            furnishings={furnishing}
            userPhotos={userPhotos}
          />
        <View style={{ width: '100%', borderRadius: 10, marginTop: 0, marginBottom: 30, backgroundColor: 'white', padding: 20, flexDirection: 'row', justifyContent: 'space-between' }}>
        <Application theme={{ rainbow: { palette: { brand: '#000' } } }} style={{ width: deviceWidth < 550 ? '100%' : '30%', alignSelf: 'flex-start', marginRight: deviceWidth < 505 ? 5 : 0 }}>
              <Button
                  label="Previous"
                  onClick={() => setCurrentStepIndex(1)}
                  variant="outline-brand"
                  style={{ borderRadius: 5, width: '100%', }}
              />
          </Application>
          <Application theme={{ rainbow: { palette: { brand: '#fed800' } } }} style={{ width: deviceWidth < 550 ? '100%' : '30%', alignSelf: 'flex-end' }}>
              <Button
                  onClick={updateListing}
                  label="Update Listing"
                  variant="brand"
                  style={{ borderRadius: 5, color: '#000', width: '100%', }}
              />
            </Application>
          </View>
      </View> : null}
    </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: Platform.OS === 'web' ? 100 : deviceHeight
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  information: { 
    width: deviceWidth < 1000 ? '90%' : '48.5%', 
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  picker: {
    height: 50,
    width: '100%',
    marginRight: 15,
    marginLeft: 15,
    borderRadius: 50,
    borderColor: '#bdc6cf',
    paddingLeft: 0,
    paddingRight: 0,
    fontSize: 16,
    fontWeight: '500',
    borderWidth: 0,
    color: '#5e6977',
  }, 
  pickerItem: {
    padding: 10,
  },
});