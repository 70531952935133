import * as React from 'react';
import {
    Text,
    View,
    Image,
    Dimensions,
} from 'react-native';

const deviceWidth = Dimensions.get("window").width;
const deviceHeight = Dimensions.get("screen").height;

export default function AboutUs() {
    return (
        <View style={{ width: '100%', maxWidth: 1200, alignSelf: 'center', padding: 40, backgroundColor: 'white', marginVertical: 30, borderRadius: 10 }}>
            <Image  source={require('../assets/images/about-us.svg')} 
                resizeMode='contain' 
                style={{ maxWidth: deviceWidth * 0.90, width: 700, height: deviceWidth < 505 ? 200 : 400, maxHeight: deviceWidth < 505 ? 200 : 400, alignSelf: 'center', marginRight: deviceWidth < 505 ? 0 : 50 }} />
            <Text style={{ fontFamily: 'pacifico-regular', paddingVertical: 25, fontSize: 30, textAlign: 'center' }}>Welcome to flatting.co.nz</Text>
            <Text style={{ fontFamily: 'pt-regular', paddingVertical: 5, paddingTop: 20, fontSize: 28 }}>About Us</Text>
            <Text style={{ fontFamily: 'pt-regular', paddingVertical: 15, fontSize: 18, lineHeight: 30 }}>
                flatting.co.nz is a New Zealand based website. We proudly operate out of Hamilton and are determined 
                to make a great website that can help Kiwi's find accomadation, spare rooms/houses and find flatmates.
            </Text>
            <Text style={{ fontFamily: 'pt-regular', paddingVertical: 15, fontSize: 18, lineHeight: 30 }}>
                We started building this website in 2020 and successfully launched in 2021. Currently we are trying to 
                find other companies to work with to provided more features to our users and gain more users on the website. 
            </Text>
            <View style={{ width: '100%', height: 1, backgroundColor: '#e2e2e2', marginVertical: 35 }} />
            <View style={{ flexDirection: 'row', paddingVertical: 15, alignItems: 'center' }}>
                <Image source={{ uri: "https://img.icons8.com/ios/50/000000/private2.png" }} style={{ height: 40, width: 40, marginRight: 10 }} />
                <Text style={{ fontFamily: 'pt-bold', fontSize: 18, maxWidth: 800, lineHeight: 30 }}>
                    Safe and secure. We never share your data with external websites for any reason. 
                </Text>
            </View>
            <View style={{ flexDirection: 'row', paddingVertical: 15, alignItems: 'center' }}>
                <Image source={{ uri: "https://img.icons8.com/ios/50/000000/trust--v1.png" }} style={{ height: 40, width: 40, marginRight: 10 }} />
                <Text style={{ fontFamily: 'pt-bold', fontSize: 18, maxWidth: 800, lineHeight: 30 }}>
                    Genuine listings and users. We search our website for fake users and listings and have phone verifications enabled to show genuine users.
                </Text>
            </View>
            <View style={{ flexDirection: 'row', paddingVertical: 15, alignItems: 'center' }}>
                <Image source={{ uri: "https://img.icons8.com/ios/50/000000/kiwi-bird.png" }} style={{ height: 40, width: 40, marginRight: 10 }} />
                <Text style={{ fontFamily: 'pt-bold', fontSize: 18, maxWidth: 800, lineHeight: 30 }}>
                    NZ owned and operated, so we know the area and our users. 
                </Text>
            </View>
            <View style={{ flexDirection: 'row', paddingVertical: 15, alignItems: 'center' }}>
                <Image source={{ uri: "https://img.icons8.com/ios/50/000000/idea--v1.png" }} style={{ height: 40, width: 40, marginRight: 10 }} />
                <Text style={{ fontFamily: 'pt-bold', fontSize: 18, maxWidth: 800, lineHeight: 30 }}>
                    Continuous improvements to our website. We are always exploring ways to make our website better. 
                </Text>
            </View>
            <View style={{ flexDirection: 'row', paddingVertical: 15, alignItems: 'center' }}>
                <Image source={{ uri: "https://img.icons8.com/ios/50/000000/one-free.png" }} style={{ height: 40, width: 40, marginRight: 10 }} />
                <Text style={{ fontFamily: 'pt-bold', fontSize: 18, maxWidth: 800, lineHeight: 30 }}>
                    Free listings and messaging. All our current features are completely free for all our users.
                </Text>
            </View>
        </View>
    );
}