import React, { useState } from 'react';

import { View, Text, Image, TouchableOpacity, TextInput, StyleSheet, Dimensions, ActivityIndicator } from 'react-native';
import { Hoverable } from 'react-native-web-hover'

import { auth } from 'firebase';

const deviceWidth = Dimensions.get("screen").width;
const deviceHeight = Dimensions.get("screen").height;

export default function ForgottenPassword({closeWebSignIn} : { closeWebSignIn: any }) {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);

    const [errorCode, setErrorCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const resetPassword = () => {
        setLoading(true);

        auth()
            .sendPasswordResetEmail(email)
            .then(function() {
                setLoading(false);
                setEmailSent(true);
            })
            .catch(function(error) {
                var errorCode = error.code;
                var errorMessage = error.message;

                setErrorCode(errorCode);
                setErrorMessage(errorMessage);
                setLoading(false);    
            });
    }

    return(
        <View style={{ flex: 1, backgroundColor: 'white', alignSelf: "center", width: '100%', maxWidth: 700, borderRadius: 10, overflow: 'hidden' }}>
                <Text style={{ alignSelf: 'center', textAlign: 'center', padding: 10, paddingTop: deviceWidth < 500 ? 35 : 30, fontSize: deviceWidth < 500 ? 18 : 22 }}>
                    Reset your <Text style={{ fontFamily: 'pacifico-regular', color: '#212121' }}>flatting </Text> account password
                </Text>
                {errorMessage !== '' && <View style={{ marginTop: 5 }}>
                    <Text style={{ fontSize: 14, textAlign: 'center', color: 'red', fontWeight: 'bold' }}>{errorMessage}</Text>
                </View>}
                <View style={styles.separator} />
                <View style={{ width: deviceWidth < 500 ? '100%' : '60%', alignSelf: 'center' }}>
                    {!emailSent && <TextInput
                        placeholder="Email"
                        style={styles.inputBox}
                        textContentType="emailAddress"
                        autoCompleteType="email"
                        onChangeText={(text) => setEmail(text)}
                    />}
                    {!emailSent && <Text style={{ marginTop: 15, paddingHorizontal: 5, lineHeight: 23 }}>
                        Enter the email you used to create your account. An email containing instructions to reset your password will be sent to you.
                    </Text>}
                    {!emailSent && <TouchableOpacity
                        onPress={resetPassword}
                        disabled={email === ''}
                        style={{ 
                            height: 40,
                            width: '100%',
                            flexDirection: 'row',
                            marginTop: 30,
                            marginBottom: 30,
                            alignSelf: 'flex-end',
                            backgroundColor: email === '' ? '#d2d2d2' : '#ff8c00',
                            borderRadius: 10,
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                        {loading && <ActivityIndicator size="small" color="white" style={{ marginRight: 10 }} />}
                        <Text style={{
                            color: 'white',
                            fontSize: 16
                        }}>Reset Password</Text>
                    </TouchableOpacity>}
                        {emailSent && <Text style={{ marginTop: 15, marginBottom: 50, paddingHorizontal: 5, lineHeight: 23 }}>
                            Email sent, Please check your email and follow the instructions to reset your password.
                        </Text>}
                </View>    
            </View>
    );
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      fontSize: 20,
      fontWeight: 'bold',
    },
    separator: {
      backgroundColor: '#eee',   
      marginVertical: 30,
      height: 1,
      width: '80%',
      alignSelf: 'center'
    },
    inputBox: { 
        marginTop: 10,
        height: 50,
        width: '100%',
        alignSelf: 'center',
        paddingLeft: 10,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: 'gray',
        fontSize: 17,
     }
  });